import React, { useState, useEffect, useRef } from "react";
import ChatIcon from "./ChatIcon";
import ChatWindow from "./ChatWindow/ChatWindow";

function LiveChat() {
  const ref = useRef(null)
  const [visible, setVisible] = useState(false);

  useEffect (() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [ref])

  const handleChatClick = () => {
    setVisible(prevVisible => !prevVisible);
  };

  return (
    <div className="live-chat-container d-none d-md-block d-lg-block" ref={ref}>
      <ChatWindow visible={visible} onClose={() => setVisible(false)} />
      <ChatIcon onClick={handleChatClick} isChatWindowOpen={visible} />
    </div>
  );
}

export default LiveChat;
