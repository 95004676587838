import React, { useState } from "react";
import "./MobileWindow.css";
import ContactEmailForm from "./ContactEmailForm";

function MobileWindow() {
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);

  return (
    <div className="overy">
      <ContactEmailForm
        setUser={(user) => setUser(user)}
        setChat={(chat) => setChat(chat)}
        visible={user === null || chat === null}
      />
    </div>
  );
}

export default MobileWindow;
