import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

const ContactForm = ({ isVisible, onClose }) => {
  const form = useRef();

  if (!isVisible) return null;

  const sendEmail = (e) => {
    e.preventDefault();

    const submitButton = e.target.querySelector(".contact-popup-button");
    submitButton.classList.add("loading");
    submitButton.disabled = true;

    emailjs
      .sendForm("service_ppcp8bs", "template_w3ywg6x", form.current, {
        publicKey: "DHhTcwyiLDvMh3zm2",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert("TrendBay DS has received your message. Expect a swift reply!");
          onClose();
        },
        (error) => {
          console.log("FAILED...", error);
          submitButton.classList.remove("loading");
          submitButton.disabled = false;
        }
      );
  };

  return (
    <div className="contactus-popup" onClick={(e) => e.stopPropagation()}>
      <button className="close-btn" onClick={onClose}>
        &times;
      </button>
      <form ref={form} className="php-email-form" onSubmit={sendEmail}>
        <h5 className="text-center font-weight-bold pb-2">Contact Us</h5>
        <div className="row">
          <div className="col-md-6 form-group">
            <input
              type="text"
              name="from_name"
              className="form-control"
              id="from_name"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="col-md-6 form-group mt-md-0">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Your Email"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Subject"
            required
          />
        </div>
        <div className="form-group mt-3">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder="Message"
            required
          ></textarea>
        </div>
        <br />
        <div className="text-center">
          <button
            type="submit"
            className="contact-popup-button contactus-popup-button"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
