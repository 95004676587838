import React, { useEffect } from "react";
import {
  MultiChatWindow,
  MultiChatSocket,
  useMultiChatLogic,
} from "react-chat-engine-advanced";

function ChatEngine({ user, chat }) {
  const chatProps = useMultiChatLogic(
    process.env.REACT_APP_CE_PROJECT_ID,
    user.email,
    user.email
  );

  useEffect(() => {
    const textarea = document.getElementById("msg-textarea");

    if (textarea) {
      setTimeout(() => {
        textarea.value = "⬞";
        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }, 100);
    }
  }, []);

  return (
    <div className="cengine">
      <MultiChatWindow {...chatProps} />
      <MultiChatSocket {...chatProps} />
    </div>
  );
}

export default ChatEngine;
