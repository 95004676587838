import React, { useState, useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import "../Boot/Boot.css";
import { auth } from "../../firebase";
import logo from "../Assets/images/logo.svg";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ContactForm from "./ContactForm";

function slugify(text) {
  if (!text) return "";
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

export default function Navbar() {
  const [{ cart, user }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [products, setProducts] = useState([]);
  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    setShowResults(value.length > 0);
  };

  const filteredItems = products.filter((product) =>
    product.product_title.toLowerCase().includes(query.toLowerCase())
  );

  const firstName = user
    ? user.displayName
      ? user.displayName.split(" ")[0]
      : null
    : null;

  const totalUniqueItems = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleAuthentication = () => {
    if (user) {
      auth.signOut().then(() => {
        window.location.href = "/";
      });
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch({ type: "SET_USER", user: authUser });
      } else {
        dispatch({ type: "SET_USER", user: null });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user, dispatch]);

  useEffect(() => {
    if (isContactFormVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isContactFormVisible]);

  return (
    <div className="header navbar navbar-expand-xl navbar-dark sticky-top d-none d-md-block">
      <div className="container-fluid pb-2">
        <a href="/">
          <img src={logo} className="header_logo" alt="TrendBay-logo" />
        </a>
        <div className="header__search pt-2 navbar-form d-lg-block d-md-none pr-3 pl-3">
          <div className="header__dropdown">
            <select
              className="header__dropdownSelect"
              onChange={(e) => {
                const selectedCategory = e.target.value;
                if (selectedCategory) {
                  window.location.href = `/shop/${selectedCategory}`;
                }
              }}
            >
              <option value="" disabled selected>
                All Categories
              </option>
              <option value="fresh-farm-produce">Fresh Farm Produce</option>
              <option value="grains-and-staples">Grains and Staples</option>
              <option value="poultry-and-meat">Poultry and Meat</option>
              <option value="catfish">Catfish</option>
              <option value="dairy-and-eggs">Dairy and Eggs</option>
              <option value="beverages">Beverages</option>
              <option value="snacks-and-confectionery">
                Snacks and Confectionery
              </option>
              <option value="canned-and-packaged-foods">
                Canned and Packaged Foods
              </option>
              <option value="frozen-foods">Frozen Foods</option>
              <option value="condiments-and-sauces">
                Condiments and Sauces
              </option>
            </select>
          </div>
          <input
            id="searchInput"
            className="header__searchInput"
            type="text"
            placeholder="Search TrendBay: groceries, poultry, etc."
            value={query}
            onChange={handleInputChange}
          />
          <div className="header__searchIcon">
            <SearchIcon fontSize="medium" />
          </div>
          {showResults && (
            <div className="search-results">
              {filteredItems.length === 0 ? (
                <div className="search-result-item">No results found</div>
              ) : (
                filteredItems.map((product) => (
                  <div key={product.id} className="search-result-item">
                    <Link
                      to={`/product/${slugify(product.product_title)}`}
                      state={{ product_id: product.id }}
                      onClick={() => setShowResults(false)}
                    >
                      <img
                        src={product.product_image}
                        alt={product.product_title}
                      />
                      <p>{product.product_title}</p>
                    </Link>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        <div className="header_option nav-item pr-2 pl-2 shop-nav">
          <Link to="/shop">
            <span className="header_optionLineOne">Shop by Category</span>
          </Link>
          <a href="/shop/all">
            <span className="header_optionLineTwo">All Products</span>
          </a>
        </div>
        <div
          className="header_option nav-item pt-2 pr-1 pl-2"
          onClick={() => setContactFormVisible(true)}
        >
          <span className="header_optionLineOne">Need Help?</span>
          <span className="header_optionLineTwo">Contact Us</span>
        </div>
        <a href="/orders" className="header_option nav-item pt-2 pr-2 pl-2">
          <span className="header_optionLineOne">History</span>
          <span className="header_optionLineTwo">Orders</span>
        </a>
        {user ? (
          <Link
            to={!user && "/login"}
            onClick={handleAuthentication}
            className="header_option nav-item pt-2 pr-2"
          >
            <span className="header_optionLineOne">Hello, {firstName}</span>
            <span className="header_optionLineTwo">Logout</span>
          </Link>
        ) : (
          <>
            <Link to="/login" className="header_option nav-item pt-2 pr-2">
              <span className="header_optionLineOne">Hello, Guest</span>
              <span className="header_optionLineTwo">Login</span>
            </Link>
            <Link to="/signup" className="header_option nav-item pt-2 pr-2">
              <button className="signup_button">Sign Up</button>
            </Link>
          </>
        )}
        <Link to="/cart">
          <div className="header_cart nav-item pt-2 pr-1">
            <ShoppingCartCheckoutIcon fontSize="large" />
            <span className="header_cartCount">{totalUniqueItems}</span>
          </div>
        </Link>
      </div>
      <div className="header__search pt-2 navbar-form d-lg-none d-md-block">
        <input
          id="searchInput"
          className="header__searchInput"
          type="text"
          placeholder="Search TrendBay: groceries, poultry, etc."
          value={query}
          onChange={handleInputChange}
        />
        <div className="header__searchIcon">
          <SearchIcon fontSize="medium" />
        </div>
        {showResults && (
          <div className="search-results">
            {filteredItems.length === 0 ? (
              <div className="search-result-item">No results found</div>
            ) : (
              filteredItems.map((product) => (
                <div key={product.id} className="search-result-item">
                  <Link
                    to={`/product/${slugify(product.product_title)}`}
                    state={{ product_id: product.id }}
                    onClick={() => setShowResults(false)}
                  >
                    <img
                      src={product.product_image}
                      alt={product.product_title}
                    />
                    <p>{product.product_title}</p>
                  </Link>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      {isContactFormVisible && (
        <div className="contact-overlay">
          <div
            className="contact-form-container"
            onClick={(e) => e.stopPropagation()}
          >
            <ContactForm
              isVisible={isContactFormVisible}
              onClose={() => setContactFormVisible(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
