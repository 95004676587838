import React from "react";
import "./MobileNavbar.css";
import "../Boot/Boot.css";
import Logo from "../Assets/images/logo.png";
import { Link } from "react-router-dom";

export default function MobileNavbar() {
  return (
    <div className="d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block mb-1">
      <div className="navbar bg-white">
        <div className="mobile_navbar-brand">
          <a href="/">
            <img
              src={Logo}
              className="mobile_header_logo mobile__logo_320"
              alt="TrendBay-logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
