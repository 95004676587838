import React from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { getCartTotal } from "../../reducer";

function Subtotal() {
  const navigate = useNavigate();
  const [{ cart }, dispatch] = useStateValue();

  const totalUniqueItems = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const calculateSubtotal = () => {
    const subtotal = getCartTotal(cart);
    return subtotal.toFixed(2);
  };

  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <div className="">
              <h6>
                <b>Subtotal:</b>
              </h6>
            </div>
            <div className="row subtotal_col">
              <div className="col">
                <p>
                  {totalUniqueItems} {totalUniqueItems === 1 ? "item" : "items"}
                </p>
              </div>
              <div className="col text-right">
                <strong>{value}</strong>
              </div>
            </div>
            <div className="row subtotal_row">
              <div className="col subtotal_row_col">
                <small>Shipping</small>
              </div>
              <div className="col text-right subtotal_row_col">
                <small>Calculated at Checkout</small>
              </div>
            </div>
            <div className="row subtotal_row">
              <div className="col subtotal_row_col">
                <small>Estimated Taxes</small>
              </div>
              <div className="col text-right subtotal_row_col">
                <small>Calculated at Checkout</small>
              </div>
            </div>
          </>
        )}
        decimalScale={2}
        value={calculateSubtotal()}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₦"}
      />

      <button onClick={(e) => navigate("/checkout")}>
        Proceed to Checkout
      </button>
    </div>
  );
}

export default Subtotal;
