import React from "react";
import { Link } from "react-router-dom";
import "./Recommended.css";

const formatPrice = (price) => {
  return !isNaN(price)
    ? `₦${parseFloat(price).toLocaleString("en-NG", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : "Invalid Price";
};

const slugify = (text) =>
  text
    ? text
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
    : "";

const Recommended = ({ recommendedProducts = [] }) => (
  <div className="recommended-products">
    <h4>You might also like:</h4>
    <div className="recommended-products-list">
      {recommendedProducts.map(
        ({ product_id, product_title, product_image, product_price }) => (
          <div key={product_id} className="recommended-product-item">
            <Link
              to={`/product/${slugify(product_title)}`}
              state={{ product_id }}
            >
              <img src={product_image} alt={product_title} className="imgrec" />
              <p>{product_title}</p>
              <p>{formatPrice(product_price)}</p>
            </Link>
          </div>
        )
      )}
    </div>
  </div>
);

export default Recommended;
