import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import "../../Components/Boot/Boot.css";
import "./ProductDetails.css";
import Navbar from "../../Components/Navbar/Navbar";
import MobileHome from "../../Components/Mobile/MobileHome";
import Footer from "../../Components/Footer/Footer";
import { useStateValue } from "../../StateProvider";
import ShareIcon from "@mui/icons-material/Share";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTitle } from "../../hooks/useTitle";
import Recommended from "./Recommended";
import LoadingScreen from "../../LoadingScreen";

const formatPrice = (price) => {
  if (isNaN(price)) return "Invalid Price";
  return parseFloat(price).toLocaleString("en-NG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

function deslugify(slug) {
  if (!slug) return "";
  return slug
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const ProductDetails = () => {
  const { product_title } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [{ cart }, dispatch] = useStateValue();
  const [expanded, setExpanded] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const q = query(
          collection(db, "products"),
          where("slug", "==", product_title) // Query using slug
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const productData = querySnapshot.docs[0].data();
          setProduct(productData);

          const productsRef = collection(db, "products");
          const allProductsSnap = await getDocs(productsRef);
          const allProducts = allProductsSnap.docs.map((doc) => doc.data());
          const sameCategoryProducts = allProducts.filter(
            (p) =>
              p.category_name === productData.category_name &&
              p.product_id !== productData.product_id
          );
          setRecommendedProducts(
            sameCategoryProducts.sort(() => 0.5 - Math.random()).slice(0, 4)
          );

          // Check if the product is already in the cart
          const inCart = cart.find(
            (item) => item.id === productData.product_id
          );
          setAddedToCart(!!inCart);
          setQuantity(inCart ? inCart.quantity : 1);
        } else {
          console.error("No such product!");
          navigate("/shop/all");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        navigate("/shop/all");
      }
    };

    fetchProductData();
  }, [product_title, cart, navigate]);

  useTitle(`${deslugify(product_title)} - TrendBay DS`);

  const renderDescription = (description) => {
    const maxLength = 200;

    if (!description) {
      return null;
    }

    if (description.length > maxLength && !expanded) {
      const visibleText = description.substring(0, maxLength);
      const hiddenText = description.substring(maxLength);

      return (
        <p>
          {visibleText}
          <span className="read-more" onClick={() => setExpanded(true)}>
            ... Read more
          </span>
          {expanded && hiddenText}
        </p>
      );
    }

    return <p>{description}</p>;
  };

  const addToCart = () => {
    if (addedToCart) {
      dispatch({
        type: "UPDATE_QUANTITY",
        id: product.product_id,
        quantity: quantity + 1,
      });
    } else {
      dispatch({
        type: "ADD_TO_CART",
        item: {
          id: product.product_id,
          title: product.product_title,
          image: product.product_image,
          price: parseFloat(product.product_price),
          quantity: 1,
        },
      });
    }
    setAddedToCart(true);
  };

  const updateQuantity = (newQuantity) => {
    if (newQuantity < 1) {
      dispatch({
        type: "REMOVE_FROM_CART",
        id: product.product_id,
      });
      setAddedToCart(false);
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        id: product.product_id,
        quantity: newQuantity,
      });
      setQuantity(newQuantity);
    }

    localStorage.setItem("cartItems", JSON.stringify(cart));
  };

  const viewCart = () => {
    navigate("/cart");
  };

  const handleShareClick = () => {
    setIsSharePopupOpen(true);
  };

  const handleShare = (url) => {
    window.open(url, "_blank");
  };

  const getCategoryLink = (categoryName) => {
    const categoryMapping = {
      "Grains & Staples": "grains-and-staples",
    };

    const urlFriendlyCategory =
      (categoryName && categoryMapping[categoryName]) ||
      (categoryName &&
        categoryName.toLowerCase().replace(/ /g, "-").replace(/&/g, "and"));

    return urlFriendlyCategory ? `/shop/${urlFriendlyCategory}` : "/";
  };

  const handleContinueShopping = () => {
    window.location.href = "/shop/all";
  };

  const formatCategoryName = (slug) => {
    return slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const notifyCart = () => {
    toast.success(`${product.product_title} added to your cart!`, {
      autoClose: 2000,
    });
  };

  const handleAddToCart = () => {
    addToCart();
    notifyCart();
  };

  if (!product) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Navbar />
      <MobileHome />
      <div className="product_details_back-to-shop-mobile d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block float-left">
        <span
          onClick={handleContinueShopping}
          className="continue-shopping-link"
        >
          <KeyboardBackspaceIcon className="icc" />
          <span className="text-muted">Continue Shopping</span>
        </span>
      </div>
      <div className="container details_container row">
        <div className="details_image col-sm-8">
          <img
            src={product.product_image}
            alt={product.product_title}
            className="details_pictures"
          />
        </div>
        <div className="product__details col-sm-4">
          <header>
            <h6 className="cat-name">
              <a
                href={getCategoryLink(product.category_name)}
                className="category-link"
              >
                {formatCategoryName(product.category_name)}
              </a>
            </h6>
            <h2 className="details_title">{product.product_title}</h2>
            <p className="mt-2 details_cart_product_quantity">
              Shipping calculated at checkout
            </p>
            <div className="details_price mt-4">
              <strong>₦{formatPrice(product.product_price)}</strong>
            </div>
          </header>
          <div className="details_footer">
            {addedToCart && (
              <div className="details_cart_product_quantity">
                <div className="home_quantity-selector">
                  <button onClick={() => updateQuantity(quantity - 1)}>
                    -
                  </button>
                  <span>{quantity}</span>
                  <button onClick={() => updateQuantity(quantity + 1)}>
                    +
                  </button>
                </div>
              </div>
            )}
            <button
              className={`btn ${
                addedToCart ? "view-cart_button" : "details_add-to-cart"
              }`}
              onClick={addedToCart ? viewCart : handleAddToCart}
              disabled={!product.available}
            >
              {product.available
                ? addedToCart
                  ? "View Cart"
                  : "Add to Cart"
                : "Out of Stock"}
            </button>
          </div>
          <div className="mt-5 details_share" onClick={handleShareClick}>
            <ShareIcon fontSize="small" /> Share
          </div>
          {isSharePopupOpen && (
            <div className="share-popup">
              <div className="share-dialog">
                <HighlightOffIcon
                  fontSize="medium"
                  style={{ color: "#ff0e0e", cursor: "pointer" }}
                  onClick={() => setIsSharePopupOpen(false)}
                />
                <div className="mt-4 social-buttons">
                  <FacebookIcon
                    fontSize="large"
                    style={{ color: "#1877F2" }}
                    onClick={() =>
                      handleShare(
                        `https://www.facebook.com/dialog/share?app_id=YOUR_FB_APP_ID&href=${encodeURIComponent(
                          window.location.href
                        )}`
                      )
                    }
                  />
                  <TwitterIcon
                    fontSize="large"
                    style={{ color: "#1DA1F2" }}
                    onClick={() =>
                      handleShare(
                        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          window.location.href
                        )}&text=Check%20out%20this%20amazing%20product%20on%20TrendBay!`
                      )
                    }
                  />
                  <WhatsAppIcon
                    fontSize="large"
                    style={{ color: "#25D366" }}
                    onClick={() =>
                      handleShare(
                        `whatsapp://send?text=${encodeURIComponent(
                          product.product_title + " - " + window.location.href
                        )}`
                      )
                    }
                  />
                  <LinkedInIcon
                    fontSize="large"
                    style={{ color: "#0A66C2" }}
                    onClick={() =>
                      handleShare(
                        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                          window.location.href
                        )}&title=${encodeURIComponent(product.product_title)}`
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className="product_description mt-4 border-bottom">
            <h5 className="pt-2">Description</h5>
            <p>{renderDescription(product.product_description)}</p>
          </div>
        </div>
        <div className="product_details_back-to-shop d-none d-md-block">
          <span
            onClick={handleContinueShopping}
            className="continue-shopping-link"
          >
            <KeyboardBackspaceIcon className="icc" />
            <span className="text-muted">Continue Shopping</span>
          </span>
        </div>
        <Recommended recommendedProducts={recommendedProducts} />
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
