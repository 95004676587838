import React from "react";
import MobileCart from "./MobileCart";
import DesktopCart from "./DesktopCart";
import { useStateValue } from "../../StateProvider";
import { useTitle } from "../../hooks/useTitle";


export default function Cart() {
  useTitle("Cart - TrendBay DS");
  const [{ cart, user }, dispatch] = useStateValue();
  

  const getFirstName = () => {
    if (user && user.displayName) {
      const names = user.displayName.split(" ");
      return names[0];
    }
    return "";
  };

  const cartTitle = user ? `${getFirstName()}'s Cart` : "My Cart";

  return (
    <div>
      <div className="mc d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block">
        <div className="mc-header navbar">
          <h5>{cartTitle}</h5>
        </div>
        <MobileCart />
      </div>
      <div className="dc d-none d-md-block">
        <DesktopCart />
      </div>
    </div>
  );
}
