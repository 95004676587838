import React, { useState } from "react";
import "./CartProduct.css";
import { useStateValue } from "../../StateProvider";

function CheckoutProduct({
  product_id,
  product_title,
  product_image,
  product_price,
}) {
  const [{ cart }, dispatch] = useStateValue();

  const alt_title = product_title;

  const cartItem = cart.find((item) => item.id === product_id);
  const initialQuantity = cartItem ? cartItem.quantity : 1;

  const [quantity, setQuantity] = useState(initialQuantity);

  const [isAdded, setIsAdded] = useState(false);

  const totalUniqueItems = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  }).format(product_price * quantity);

  const removeFromCart = () => {
    dispatch({
      type: "REMOVE_FROM_CART",
      id: product_id,
    });
  };

  return (
    <div className="cartProduct cart-main">
      <div className="row border-bottom">
        <div className="row cart__main align-items-center">
          <div className="col-2 cart_product_col-2 dizdiz">
            <img
              className="img-fluid cart_product_img"
              src={product_image}
              alt={alt_title}
            />
            <div className="checkout-quantity-banner">
              <small>{quantity}</small>
            </div>
          </div>
          <div className="text-left cart_product_col col-col_check">
            <div className="cart-Product_title mb-2 mt-2">
              {product_title}
            </div>
            <strong className="cicici">{formattedPrice}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutProduct;
