import React, { useEffect } from "react";
import "./MobileNavbar.css";
import "../Boot/Boot.css";
import greenLogo from "../Assets/images/green-logo.png";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import MobileSearch from "./MobileSearch";

export default function MobileNavbar() {
  useEffect(() => {
    const handleScroll = () => {
      const searchMobile = document.querySelector(".search-mobile");
      if (window.scrollY > 50) {
        searchMobile.classList.add("scrolled");
      } else {
        searchMobile.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block mb-1">
      <div className="mobile_header navbar">
        <div className="mobile_navbar-brand">
          <a href="/">
            <img
              src={greenLogo}
              className="mobile_header_logo mobile__logo_320"
              alt="TrendBay-logo"
            />
          </a>
        </div>
      </div>
      <MobileSearch />
    </div>
  );
}
