import React, { useEffect } from "react";
import "./LoadingScreen.css";
import SpinLogo from "../src/Components/Assets/images/tb_logo.png";

const LoadingScreen = () => {
  useEffect(() => {
    const originalColor = document
      .querySelector("meta[name='theme-color']")
      .getAttribute("content");
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#ffffff");
    }

    return () => {
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", originalColor);
      }
    };
  }, []);

  return (
    <div className="loading-screen">
      <img src={SpinLogo} alt="Loading Logo" className="loading-logo" />
    </div>
  );
};

export default LoadingScreen;
