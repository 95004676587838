import React from "react";
import Filter from "../Shop/Filter";
import "./SideBar.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function SideBar({ onFilterChange, defaultCategory }) {
  return (
    <div className="sidebar">
      <div className="filter-sort-bar">
        <Filter
          onFilterChange={onFilterChange}
          defaultCategory={defaultCategory}
        />
      </div>
      <div className="socials">
        <h4 className="mb-3">Connect with TrendBay</h4>
        <div className="download-catalog">
          <a
            href="https://www.instagram.com/trendbayds"
            target="_blank"
            className="button-social instagram d-flex"
            rel="noreferrer"
          >
            <span>
              <InstagramIcon />
            </span>
            <p className="ml-2 mt-3">Follow TrendBay on Instagram</p>
          </a>
          <a
            href="https://www.facebook.com/trendbayds"
            target="_blank"
            className="button-social facebook d-flex"
            rel="noreferrer"
          >
            <span>
              <FacebookIcon />
            </span>
            <p className="ml-2 mt-3">Like TrendBay on Facebook</p>
          </a>
          <a
            href="https://linkedin.com/company/trendbayds"
            target="_blank"
            className="button-social linkedin d-flex"
            rel="noreferrer"
          >
            <span>
              <LinkedInIcon />
            </span>
            <p className="ml-2 mt-3">Follow TrendBay on Linkedin</p>
          </a>
          <a
            href="https://www.youtube.com/@TrendBayDS"
            target="_blank"
            className="button-social youtube d-flex"
            rel="noreferrer"
          >
            <span>
              <YouTubeIcon />
            </span>
            <p className="ml-2 mt-3">Subscribe to TrendBay on YouTube</p>
          </a>
        </div>
      </div>
    </div>
  );
}
