import React, { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { getCartTotal } from "../../reducer";
import { PaystackButton } from "react-paystack";
import publicKey from "../../pkk";
import "./CheckoutTotal.css";
import ReviewCart from "./ReviewCart";

function CheckoutTotal({
  user,
  onPaymentSuccess,
  email,
  name,
  amount,
  phone,
  isFormValid,
}) {
  const [{ cart }, dispatch] = useStateValue();
  const [orderTotal, setOrderTotal] = useState(0);
  const navigate = useNavigate();
  const [showCart, setShowCart] = useState(false);

  const componentProps = {
    email,
    amount: amount * 100,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (response) => {
      onPaymentSuccess(response.reference);
    },
    onClose: () => alert("Payment Closed!"),
  };

  const handleToggleCart = () => {
    setShowCart(!showCart);
  };

  useEffect(() => {
    const calculateOrderTotal = () => {
      const cartTotal = getCartTotal(cart);
      const shippingCost = 5000;
      const estimatedTax = cartTotal * 0.075;
      return (cartTotal + shippingCost + estimatedTax).toFixed(2);
    };

    const newOrderTotal = calculateOrderTotal();
    setOrderTotal(newOrderTotal);
  }, [cart]);

  const handlePayNow = () => {
    if (isFormValid && isFormValid()) {
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <div className="checkout__total">
      <div className="cart_title checkout_title_section">
        <div className="checkout__title check-check mt-3">
          <h5>
            <strong>ORDER SUMMARY</strong>
          </h5>
          <p className="ptag_checkout" onClick={handleToggleCart}>
            {showCart ? "Hide" : "Show"}
          </p>
        </div>
        {showCart && <ReviewCart />}
      </div>
      <CurrencyFormat
        renderText={(value) => (
          <>
            <div className="row checkout__total_row">
              <div className="col checkout__col">
                <p>Subtotal</p>
              </div>
              <div className="col text-right">
                <p>{`₦${getCartTotal(cart).toFixed(2)}`}</p>
              </div>
            </div>
            <div className="row checkout__total_row">
              <div className="col checkout__col">
                <p>Shipping</p>
              </div>
              <div className="col text-right">
                <p>₦5,000</p>
              </div>
            </div>
            <div className="row checkout__total_row">
              <div className="col checkout__col">
                <p>Estimated Tax (7.5%)</p>
              </div>
              <div className="col text-right">
                <p>{`₦${(getCartTotal(cart) * 0.075).toFixed(2)}`}</p>
              </div>
            </div>
            <div className="row border-top border-bottom">
              <div className="col checkout__col pt-2 pb-4">
                <h6>
                  <b>Total</b>
                </h6>
              </div>
              <div className="col text-right pt-2 pb-4">
                <strong>{`${value}`}</strong>
              </div>
            </div>
          </>
        )}
        decimalScale={2}
        value={orderTotal}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₦"}
      />
      {isFormValid && isFormValid() ? (
        <PaystackButton className="pstackButton" {...componentProps} />
      ) : (
        <button className="pstackButton">Pay Now</button>
      )}
    </div>
  );
}

export default CheckoutTotal;
