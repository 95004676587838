import React from "react";
import "./EmptyCart.css";
import "../../Components/Boot/Boot.css";
import TBLogo from "../../Components/Assets/images/logo.png";
import { Link } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";

function EmptyCart() {
  useTitle("Cart - TrendBay DS");
  return (
    <div>
      <div className="container mt-100 empty_mt-100">
        <div className="row">
          <div className="col-md-12 empty_col-md-12">
            <div className="card empty_card">
              <div className="card-body cart empty_card-body empty_cart">
                <div className="col-sm-12 empty-cart-cls text-center empty_card_body">
                  <h2>
                    <strong>Your Cart is Empty</strong>
                  </h2>
                  <h5>Let's go back and add some items 😉</h5>
                  <a href="/">
                    <button
                      className="btn btn-success cart-btn-transform m-3 mt-3"
                      data-abc="true"
                    >
                      Go Home
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyCart;
