import scroll1 from "./images/scroll/circle/scroll1.png";
import scroll2 from "./images/scroll/circle/scroll2.png";
import scroll3 from "./images/scroll/circle/scroll3.png";
import scroll4 from "./images/scroll/circle/scroll4.png";
import scroll5 from "./images/scroll/circle/scroll5.png";
import scroll6 from "./images/scroll/circle/scroll6.png";
import scroll7 from "./images/scroll/circle/scroll7.png";
import scroll8 from "./images/scroll/circle/scroll8.png";
import scroll9 from "./images/scroll/circle/scroll9.png";
import scroll10 from "./images/scroll/circle/scroll10.png";
import product1 from "../../Components/Assets/images/Products/FFP/1.png";
import product2 from "../../Components/Assets/images/Products/FFP/2.png";
import product3 from "../../Components/Assets/images/Products/FFP/3.png";
import product4 from "../../Components/Assets/images/Products/FFP/4.png";
import product5 from "../../Components/Assets/images/Products/FFP/5.png";
import product6 from "../../Components/Assets/images/Products/FFP/6.png";
import product7 from "../../Components/Assets/images/Products/GNS/7.png";
import product8 from "../../Components/Assets/images/Products/GNS/8.png";
import product9 from "../../Components/Assets/images/Products/GNS/9.png";
import product10 from "../../Components/Assets/images/Products/GNS/10.png";
import product11 from "../../Components/Assets/images/Products/GNS/11.png";
import product12 from "../../Components/Assets/images/Products/GNS/12.png";
import product13 from "../../Components/Assets/images/Products/PLM/13.png";
import product14 from "../../Components/Assets/images/Products/PLM/14.png";
import product15 from "../../Components/Assets/images/Products/PLM/15.png";
import product16 from "../../Components/Assets/images/Products/PLM/16.png";
import product17 from "../../Components/Assets/images/Products/PLM/17.png";
import product18 from "../../Components/Assets/images/Products/PLM/18.png";
import product19 from "../../Components/Assets/images/Products/DAE/19.png";
import product20 from "../../Components/Assets/images/Products/DAE/20.png";
import product21 from "../../Components/Assets/images/Products/DAE/21.png";
import product22 from "../../Components/Assets/images/Products/DAE/22.png";
import product23 from "../../Components/Assets/images/Products/DAE/23.png";
import product24 from "../../Components/Assets/images/Products/DAE/24.png";
import product25 from "../../Components/Assets/images/Products/BEV/25.png";
import product26 from "../../Components/Assets/images/Products/BEV/26.png";
import product27 from "../../Components/Assets/images/Products/BEV/27.png";
import product28 from "../../Components/Assets/images/Products/BEV/28.png";
import product29 from "../../Components/Assets/images/Products/BEV/29.png";
import product30 from "../../Components/Assets/images/Products/BEV/30.png";
import product31 from "../../Components/Assets/images/Products/SNC/31.png";
import product32 from "../../Components/Assets/images/Products/SNC/32.png";
import product33 from "../../Components/Assets/images/Products/SNC/33.png";
import product34 from "../../Components/Assets/images/Products/SNC/34.png";
import product35 from "../../Components/Assets/images/Products/SNC/35.png";
import product36 from "../../Components/Assets/images/Products/SNC/36.png";
import product37 from "../../Components/Assets/images/Products/CPF/37.png";
import product38 from "../../Components/Assets/images/Products/CPF/38.png";
import product39 from "../../Components/Assets/images/Products/CPF/39.png";
import product40 from "../../Components/Assets/images/Products/CPF/40.png";
import product41 from "../../Components/Assets/images/Products/CPF/41.png";
import product42 from "../../Components/Assets/images/Products/CPF/42.png";
import product43 from "../../Components/Assets/images/Products/FRF/43.png";
import product44 from "../../Components/Assets/images/Products/FRF/44.png";
import product45 from "../../Components/Assets/images/Products/FRF/45.png";
import product46 from "../../Components/Assets/images/Products/FRF/46.png";
import product47 from "../../Components/Assets/images/Products/FRF/47.png";
import product48 from "../../Components/Assets/images/Products/FRF/48.png";
import product49 from "../../Components/Assets/images/Products/CNS/49.png";
import product50 from "../../Components/Assets/images/Products/CNS/50.png";
import product51 from "../../Components/Assets/images/Products/CNS/51.png";
import product52 from "../../Components/Assets/images/Products/CNS/52.png";
import product53 from "../../Components/Assets/images/Products/CNS/53.png";
import product54 from "../../Components/Assets/images/Products/CNS/54.png";

export const nigerianStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const category_list = [
  {
    category_id: "C1",
    category_name: "Fresh Farm Produce",
    category_slug: "Farm",
    category_image: scroll1,
  },
  {
    category_id: "C2",
    category_name: "Grains and Staples",
    category_slug: "Grains",
    category_image: scroll2,
  },
  {
    category_id: "C3",
    category_name: "Poultry and Meat",
    category_slug: "Poultry",
    category_image: scroll3,
  },
  {
    category_id: "C5",
    category_name: "Dairy and Eggs",
    category_slug: "Dairy",
    category_image: scroll5,
  },
  {
    category_id: "C6",
    category_name: "Beverages",
    category_slug: "Beverages",
    category_image: scroll6,
  },
  {
    category_id: "C7",
    category_name: "Snacks and Confectionery",
    category_slug: "Snacks",
    category_image: scroll7,
  },
  {
    category_id: "C8",
    category_name: "Canned and Packaged Foods",
    category_slug: "Pantry",
    category_image: scroll8,
  },
  {
    category_id: "C9",
    category_name: "Frozen Foods",
    category_slug: "Frozen",
    category_image: scroll9,
  },
  {
    category_id: "C10",
    category_name: "Condiments and Sauces",
    category_slug: "Sauces",
    category_image: scroll10,
  },
];

export const category_details = [
  {
    category_id: "C1",
    category_code: "FFP",
    category_name: "Fresh Farm Produce",
    category_slug: "Farm",
  },
  {
    category_id: "C2",
    category_code: "GNS",
    category_name: "Grains and Staples",
    category_slug: "Grains",
  },
  {
    category_id: "C3",
    category_code: "PLM",
    category_name: "Poultry and Meat",
    category_slug: "Poultry",
  },
  {
    category_id: "C4",
    category_code: "CAT",
    category_name: "Catfish",
    category_slug: "Catfish",
  },
  {
    category_id: "C5",
    category_code: "DAE",
    category_name: "Dairy and Eggs",
    category_slug: "Dairy",
  },
  {
    category_id: "C6",
    category_code: "BEV",
    category_name: "Beverages",
    category_slug: "Beverages",
  },
  {
    category_id: "C7",
    category_code: "SNC",
    category_name: "Snacks and Confectionery",
    category_slug: "Snacks",
  },
  {
    category_id: "C8",
    category_code: "CPF",
    category_name: "Canned and Packaged Foods",
    category_slug: "Pantry",
  },
  {
    category_id: "C9",
    category_code: "FRF",
    category_name: "Frozen Foods",
    category_slug: "Frozen",
  },
  {
    category_id: "C10",
    category_code: "CNS",
    category_name: "Condiments and Sauces",
    category_slug: "Sauces",
  },
];

export const products = [
  {
    product_id: "tb-ds6oo",
    product_image: product1,
    product_title: "Tomatoes 1kg",
    product_price: "7,000.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Discover the freshness and flavor of our premium 1kg pack of fresh tomatoes, perfect for elevating your culinary creations. These vibrant red tomatoes are carefully hand-picked to ensure the highest quality and peak ripeness. Whether you're preparing a fresh salad, a rich tomato sauce, a hearty soup, or a savory stew, our tomatoes will add a burst of flavor and color to your dishes.

      Our fresh tomatoes are packed with essential nutrients, making them a healthy addition to any diet. They are an excellent source of vitamins A and C, potassium, and powerful antioxidants like lycopene, which is known for its health benefits, including reducing the risk of heart disease and certain types of cancer.

      Grown locally and harvested at the peak of freshness, our tomatoes offer a taste that is both sweet and tangy, enhancing the flavor profile of any recipe. They are also incredibly versatile and can be used in a variety of dishes, from traditional Nigerian stews to international cuisines.

      For the best in fresh produce, choose our 1kg pack of tomatoes. Perfect for home cooks and professional chefs alike, our tomatoes will help you create meals that are both delicious and nutritious. Experience the difference that fresh, high-quality tomatoes can make in your cooking.

      Key Features:
      ⁃ Hand-picked for peak ripeness and quality
      ⁃ Rich in vitamins A and C, potassium, and antioxidants
      ⁃ Versatile use in salads, sauces, soups, stews, and more
      ⁃ Locally grown and harvested for maximum freshness
      ⁃ Enhances the flavor and color of any dish
    `,
  },
  {
    product_id: "tb-ds72w",
    product_image: product2,
    product_title: "Pineapple (Full)",
    product_price: "1,300.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Enjoy the tropical sweetness of our premium whole pineapple, perfect for adding a burst of flavor to your meals and snacks. Each pineapple is carefully selected to ensure peak ripeness, providing a juicy and fragrant fruit that is bursting with natural sweetness.

      Pineapples are not only delicious but also packed with essential nutrients. They are an excellent source of vitamin C, manganese, and dietary fiber, which can help support a healthy immune system, promote digestive health, and provide antioxidant benefits. The natural enzymes in pineapples, like bromelain, are known for their anti-inflammatory properties and can aid in digestion.

      Whether enjoyed fresh, grilled, juiced, or added to your favorite dishes, our whole pineapples are incredibly versatile. Use them to create refreshing fruit salads, tropical smoothies, tangy salsas, or as a sweet addition to savory dishes. The vibrant flavor and aroma of fresh pineapple can enhance both sweet and savory recipes, making it a staple in any kitchen.

      For the freshest and most flavorful pineapples, choose our whole pineapples. Perfect for family meals, entertaining guests, or simply enjoying as a healthy snack, our pineapples bring a taste of the tropics to your table.

      Key Features:
      ⁃ Carefully selected for peak ripeness and natural sweetness
      ⁃ Rich in vitamin C, manganese, and dietary fiber
      ⁃ Contains natural enzymes like bromelain for digestive health
      ⁃ Versatile use in fruit salads, smoothies, salsas, and more
      ⁃ Enhances both sweet and savory dishes with tropical flavor
    `,
  },
  {
    product_id: "tb-ds56c",
    product_image: product3,
    product_title: "Scent Leaf (Efirin)",
    product_price: "500.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Discover the aromatic essence of our fresh Scent Leaf (Efirin), a staple in Nigerian cuisine known for its distinctive flavor and numerous health benefits. This versatile herb, also known as African basil, is hand-picked to ensure the highest quality and freshness, making it an essential addition to your culinary repertoire.

      Scent Leaf is renowned for its aromatic, peppery taste and is commonly used in soups, stews, sauces, and traditional Nigerian dishes. It adds a unique flavor that enhances the overall taste of your meals. Whether you're preparing a hearty pot of efo riro, pepper soup, or any other favorite dish, Scent Leaf will bring out the best in your cooking.

      Beyond its culinary uses, Scent Leaf is also prized for its medicinal properties. It is rich in essential nutrients like vitamins A and C, calcium, iron, and antioxidants. These nutrients contribute to its various health benefits, including supporting the immune system, promoting healthy digestion, and providing anti-inflammatory properties.

      Embrace the freshness and versatility of our Scent Leaf (Efirin). Perfect for home cooks and chefs alike, it will elevate your dishes with its vibrant flavor and healthful qualities. Add this fresh herb to your kitchen and experience the difference it makes in your cooking.

      Key Features:
      ⁃ Hand-picked for freshness and quality
      ⁃ Aromatic and peppery flavor that enhances dishes
      ⁃ Rich in vitamins A and C, calcium, iron, and antioxidants
      ⁃ Versatile use in soups, stews, sauces, and more
      ⁃ Known for its medicinal properties and health benefits
    `,
  },
  {
    product_id: "tb-ds8n4",
    product_image: product4,
    product_title: "Pepper - Red (Big Basket)",
    product_price: "3,200.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Spice up your culinary creations with our vibrant Red Peppers, available in a generous big basket size. These fresh, hand-picked red peppers are perfect for adding a burst of color, flavor, and heat to a wide variety of dishes. 

      Red peppers are not only known for their bold, sweet, and slightly spicy taste but also for their nutritional benefits. They are an excellent source of vitamins A, C, and B6, as well as essential antioxidants like beta-carotene and lycopene. These nutrients help support a healthy immune system, improve skin health, and provide anti-inflammatory benefits.

      Versatile and flavorful, our red peppers can be used in countless ways in the kitchen. Whether you’re making a rich stew, a zesty salsa, a vibrant stir-fry, or roasting them for a smoky flavor, these peppers will enhance the taste and presentation of your meals. They can also be used fresh in salads, stuffed with delicious fillings, or blended into sauces and dips.

      For the freshest and most flavorful peppers, choose our big basket of red peppers. Perfect for home cooks, caterers, and chefs, these peppers will help you create meals that are as nutritious as they are delicious.

      Key Features:
      ⁃ Hand-picked for freshness and quality
      ⁃ Bold, sweet, and slightly spicy flavor
      ⁃ Rich in vitamins A, C, and B6, and antioxidants
      ⁃ Versatile use in stews, salsas, stir-fries, and more
      ⁃ Enhances the flavor and color of a variety of dishes
    `,
  },
  {
    product_id: "tb-ds48iy",
    product_image: product5,
    product_title: "Carrot 1kg",
    product_price: "5,200.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Enjoy the crisp, sweet flavor of our fresh carrots, available in a convenient 1kg pack. These vibrant orange carrots are carefully selected for their quality and freshness, making them a perfect addition to your kitchen.

      Carrots are a highly nutritious vegetable, packed with essential vitamins and minerals. They are an excellent source of beta-carotene, which is converted into vitamin A in the body, promoting good vision, healthy skin, and a strong immune system. They also contain vitamins K, C, and B6, as well as potassium and fiber.

      Versatile and delicious, our carrots can be used in a variety of dishes. Enjoy them raw as a healthy snack, add them to salads for a crunchy texture, or cook them in soups, stews, and stir-fries. They can also be roasted, steamed, or blended into smoothies and juices for a nutritious boost.

      For the freshest and most flavorful carrots, choose our 1kg pack. Ideal for home cooks and professional chefs, these carrots will help you create tasty and nutritious meals that everyone will love.

      Key Features:
      ⁃ Carefully selected for quality and freshness
      ⁃ Rich in beta-carotene, vitamins K, C, and B6, potassium, and fiber
      ⁃ Versatile use in salads, soups, stews, stir-fries, and more
      ⁃ Enhances the flavor and texture of a variety of dishes
    `,
  },
  {
    product_id: "tb-ds06qw",
    product_image: product6,
    product_title: "Irish Potatoes - 1 kg",
    product_price: "4,800.00",
    category_name: "Fresh Farm Produce",
    product_description: `
      Discover the versatile and hearty goodness of our Irish Potatoes, available in a convenient 1kg pack. These fresh, high-quality potatoes are perfect for creating a wide range of delicious and satisfying meals.

      Irish potatoes are a nutritious staple, rich in essential vitamins and minerals. They are an excellent source of vitamin C, potassium, and dietary fiber, which support overall health and well-being. Potatoes also provide a good amount of vitamin B6 and antioxidants, which can help boost your immune system and promote healthy skin.

      Whether you prefer them boiled, mashed, roasted, or fried, our Irish potatoes are incredibly versatile and easy to prepare. Use them to make classic dishes like mashed potatoes, potato salad, and baked potatoes, or get creative with gratins, soups, and stews. Their smooth texture and rich flavor make them a favorite in many kitchens.

      For the best in fresh produce, choose our 1kg pack of Irish potatoes. Perfect for home cooks and chefs alike, these potatoes will help you create meals that are both comforting and nutritious.

      Key Features:
      ⁃ High-quality, fresh Irish potatoes
      ⁃ Rich in vitamin C, potassium, and dietary fiber
      ⁃ Versatile use in a variety of dishes, from mashed potatoes to stews
      ⁃ Smooth texture and rich flavor
    `,
  },
  {
    product_id: "tb-ds80er",
    product_image: product7,
    product_title: "Rice - 4L (Local)",
    product_price: "8,300.00",
    category_name: "Grains & Staples",
    product_description: `
      Enjoy the rich, authentic flavor of our locally sourced Rice, available in a generous 4L pack. This high-quality rice is perfect for creating a variety of traditional and contemporary dishes, making it a staple in every kitchen.

      Our local rice is known for its distinct taste and texture, which pairs well with a wide range of ingredients. It is an excellent source of energy, providing essential carbohydrates that fuel your daily activities. Additionally, it contains important vitamins and minerals such as B vitamins, iron, and magnesium, contributing to a balanced diet.

      Whether you're cooking jollof rice, fried rice, or plain white rice, our local rice will enhance the flavor and aroma of your meals. It is also ideal for preparing traditional Nigerian dishes like rice and beans or rice and stew. The grains cook to a fluffy perfection, making each bite a delight.

      For the best in locally grown staples, choose our 4L pack of local rice. Perfect for home cooks and chefs, this rice will help you create delicious and nutritious meals that your family will love.

      Key Features:
      ⁃ Locally sourced for authentic flavor and quality
      ⁃ Rich in essential carbohydrates, vitamins, and minerals
      ⁃ Versatile use in a variety of traditional and contemporary dishes
      ⁃ Fluffy texture and delightful aroma
    `,
  },
  {
    product_id: "tb-ds19nk",
    product_image: product8,
    product_title: "Yam - Medium",
    product_price: "4,500.00",
    category_name: "Grains & Staples",
    product_description: `
      Experience the hearty goodness of our Medium Yam, a versatile staple in Nigerian cuisine. This fresh, high-quality yam is perfect for a variety of dishes, offering a delicious and nutritious addition to your meals.

      Yams are a rich source of complex carbohydrates, providing sustained energy and keeping you full for longer. They are also packed with essential nutrients such as vitamins C and B6, potassium, and dietary fiber, which support overall health and well-being.

      Our medium yams can be used in numerous ways in the kitchen. They are ideal for boiling, frying, or roasting, and can be made into traditional Nigerian dishes like pounded yam, yam porridge, or yam fries. Their smooth, starchy texture and slightly sweet flavor make them a favorite in many households.

      For the freshest and most versatile yams, choose our medium-sized yam. Perfect for home cooks and chefs, these yams will help you create hearty and nutritious meals that everyone will enjoy.

      Key Features:
      ⁃ Fresh, high-quality medium-sized yam
      ⁃ Rich in complex carbohydrates, vitamins, and minerals
      ⁃ Versatile use in a variety of traditional and contemporary dishes
      ⁃ Smooth, starchy texture with a slightly sweet flavor
    `,
  },
  {
    product_id: "1tb-ds99",
    product_image: product9,
    product_title: "Garri Ijebu - 4L",
    product_price: "5,000.00",
    category_name: "Grains & Staples",
    product_description: `
      Discover the unique taste and texture of our Garri Ijebu, available in a convenient 4L pack. This high-quality cassava product is a staple in many Nigerian households, known for its sour flavor and fine texture.

      Garri Ijebu is made from fermented and roasted cassava, providing a rich source of carbohydrates that are essential for energy. It is also packed with dietary fiber, which supports digestive health, and contains small amounts of essential vitamins and minerals.

      Our Garri Ijebu is perfect for making a variety of traditional dishes. Enjoy it as eba, a popular accompaniment to soups and stews, or simply soak it in water with sugar and milk for a quick and refreshing snack. Its versatility and unique flavor make it a favorite among many.

      For the best in traditional Nigerian staples, choose our 4L pack of Garri Ijebu. Perfect for home cooks and chefs, this garri will help you create delicious and satisfying meals that celebrate the rich culinary heritage of Nigeria.

      Key Features:
      ⁃ High-quality Garri Ijebu with a unique sour flavor
      ⁃ Rich in carbohydrates and dietary fiber
      ⁃ Versatile use in traditional Nigerian dishes
      ⁃ Fine texture and refreshing taste
    `,
  },
  {
    product_id: "92tb-ds10",
    product_image: product10,
    product_title: "Beans - 4L",
    product_price: "15,000.00",
    category_name: "Grains & Staples",
    product_description: `
      Enjoy the wholesome nutrition of our premium Beans, available in a substantial 4L pack. These high-quality beans are perfect for a variety of dishes, offering a delicious and nutritious addition to your diet.

      Beans are an excellent source of plant-based protein, making them an essential part of a balanced diet. They are also rich in dietary fiber, which supports digestive health, and contain important vitamins and minerals such as iron, magnesium, and B vitamins.

      Our beans can be used in countless ways in the kitchen. Whether you're making a hearty bean soup, a savory beans porridge, or adding them to salads and stews, these beans will enhance the flavor and nutritional value of your meals. They are also ideal for traditional Nigerian dishes like moi moi and akara.

      For the best in staple foods, choose our 4L pack of beans. Perfect for home cooks and chefs, these beans will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ High-quality beans rich in plant-based protein
      ⁃ Packed with dietary fiber, vitamins, and minerals
      ⁃ Versatile use in a variety of dishes, from soups to stews
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "9tb-ds1",
    product_image: product11,
    product_title: "Ofada Rice - 4L",
    product_price: "12,800.00",
    category_name: "Grains & Staples",
    product_description: `
      Experience the unique taste and aroma of our premium Ofada Rice, available in a generous 4L pack. This high-quality, locally sourced rice is a beloved staple in Nigerian cuisine, known for its distinct flavor and health benefits.

      Ofada rice is a rich source of complex carbohydrates, providing sustained energy and keeping you full for longer. It is also packed with essential nutrients such as vitamins B1, B3, and B6, magnesium, and dietary fiber, which support overall health and well-being.

      Our Ofada rice is perfect for preparing traditional Nigerian dishes like Ofada stew (ayamase) and can also be enjoyed with a variety of soups and sauces. Its slightly nutty flavor and aromatic scent make it a favorite in many households.

      For the best in locally grown staples, choose our 4L pack of Ofada rice. Perfect for home cooks and chefs, this rice will help you create delicious and nutritious meals that celebrate the rich culinary heritage of Nigeria.

      Key Features:
      ⁃ Locally sourced Ofada rice with a unique flavor
      ⁃ Rich in complex carbohydrates, vitamins, and minerals
      ⁃ Versatile use in traditional Nigerian dishes
      ⁃ Nutty flavor and aromatic scent
    `,
  },
  {
    product_id: "9tb-ds012",
    product_image: product12,
    product_title: "Egusi Peeled - Whole 170g",
    product_price: "600.00",
    category_name: "Grains & Staples",
    product_description: `
      Discover the rich flavor and versatility of our Peeled Egusi, available in a convenient 170g pack. This high-quality melon seed is a key ingredient in many traditional Nigerian dishes, known for its unique taste and health benefits.

      Egusi is an excellent source of plant-based protein, essential fatty acids, and dietary fiber. It also contains important vitamins and minerals such as vitamins A, B1, B2, and C, as well as calcium, magnesium, and potassium, contributing to a balanced diet.

      Our peeled Egusi is perfect for making a variety of traditional dishes such as Egusi soup, which is a popular accompaniment to pounded yam, eba, or fufu. It can also be used to add flavor and nutrition to stews, sauces, and other savory dishes.

      For the best in traditional Nigerian ingredients, choose our 170g pack of peeled Egusi. Perfect for home cooks and chefs, this Egusi will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ High-quality peeled Egusi with a unique flavor
      ⁃ Rich in plant-based protein, essential fatty acids, and dietary fiber
      ⁃ Versatile use in traditional Nigerian dishes
      ⁃ Packed with essential vitamins and minerals
    `,
  },
  {
    product_id: "tb-ds013",
    product_image: product13,
    product_title: "Goat Meat 1kg",
    product_price: "8,000.00",
    category_name: "Poultry & Meat",
    product_description: `
      Enjoy the rich, savory taste of our premium Goat Meat, available in a generous 1kg pack. This high-quality meat is a favorite in many cuisines, known for its distinct flavor and versatility in cooking.

      Goat meat is a great source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our goat meat is perfect for preparing a variety of traditional and contemporary dishes. Whether you're making goat meat pepper soup, asun (spicy grilled goat meat), or adding it to stews and curries, this meat will enhance the flavor and nutrition of your meals.

      For the best in fresh, high-quality meat, choose our 1kg pack of goat meat. Perfect for home cooks and chefs, this goat meat will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium goat meat with a rich, savory flavor
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in traditional and contemporary dishes
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "924tb-ds",
    product_image: product14,
    product_title: "Lamb Chops - Cutlets 500g",
    product_price: "5,250.00",
    category_name: "Poultry & Meat",
    product_description: `
      Savor the tender and flavorful taste of our premium Lamb Chops, available in a convenient 500g pack. These high-quality lamb cutlets are perfect for creating gourmet dishes, offering a delicious and nutritious addition to your meals.

      Lamb chops are an excellent source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our lamb chops are perfect for grilling, roasting, or pan-searing. Their tender texture and rich flavor make them a favorite in many households. Whether you're preparing a special dinner or a simple meal, these lamb cutlets will enhance the flavor and presentation of your dishes.

      For the best in fresh, high-quality meat, choose our 500g pack of lamb chops. Perfect for home cooks and chefs, these lamb cutlets will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium lamb chops with a tender and flavorful taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in grilling, roasting, or pan-searing
      ⁃ Enhances the flavor and presentation of your dishes
    `,
  },
  {
    product_id: "0tb-ds15",
    product_image: product15,
    product_title: "Beef 1kg",
    product_price: "5900",
    category_name: "Poultry & Meat",
    product_description: `
      Enjoy the rich, hearty taste of our premium Beef, available in a generous 1kg pack. This high-quality meat is a staple in many cuisines, known for its versatility and nutritional benefits.

      Beef is an excellent source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our beef is perfect for preparing a variety of traditional and contemporary dishes. Whether you're making beef stew, grilling steaks, or adding it to stir-fries and soups, this meat will enhance the flavor and nutrition of your meals.

      For the best in fresh, high-quality meat, choose our 1kg pack of beef. Perfect for home cooks and chefs, this beef will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium beef with a rich, hearty taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in traditional and contemporary dishes
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "tb-ds636",
    product_image: product16,
    product_title: "Beef With Skin 1kg",
    product_price: "3000",
    category_name: "Poultry & Meat",
    product_description: `
      Enjoy the unique taste and texture of our premium Beef With Skin, available in a generous 1kg pack. This high-quality meat is perfect for a variety of traditional and contemporary dishes, offering a rich flavor and satisfying chew.

      Beef with skin is an excellent source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our beef with skin is perfect for making traditional dishes like nkwobi, pepper soup, or asun, where the skin adds a unique texture and flavor. It can also be used in stews, soups, and other savory dishes to enhance the overall taste and nutrition.

      For the best in fresh, high-quality meat, choose our 1kg pack of beef with skin. Perfect for home cooks and chefs, this beef will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium beef with skin for a unique taste and texture
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in traditional and contemporary dishes
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "tb-ds736",
    product_image: product17,
    product_title: "Beef - Boneless 500g",
    product_price: "5900",
    category_name: "Poultry & Meat",
    product_description: `
      Discover the tender and flavorful taste of our premium Boneless Beef, available in a convenient 500g pack. This high-quality meat is perfect for a variety of dishes, offering a lean and nutritious option for your meals.

      Boneless beef is an excellent source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our boneless beef is perfect for grilling, stir-frying, or adding to stews and soups. Its tender texture and rich flavor make it a favorite in many households. Whether you're preparing a special dinner or a quick meal, this boneless beef will enhance the flavor and nutrition of your dishes.

      For the best in fresh, high-quality meat, choose our 500g pack of boneless beef. Perfect for home cooks and chefs, this beef will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium boneless beef for a tender and flavorful taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in grilling, stir-frying, and stews
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "92tb-ds",
    product_image: product18,
    product_title: "Chicken Liver 1kg",
    product_price: "1150",
    category_name: "Poultry & Meat",
    product_description: `
      Enjoy the rich, nutrient-dense taste of our premium Chicken Liver, available in a generous 1kg pack. This high-quality organ meat is a favorite in many cuisines, known for its distinct flavor and health benefits.

      Chicken liver is an excellent source of high-quality protein and is rich in essential vitamins and minerals such as iron, vitamin A, and vitamin B12. These nutrients are crucial for maintaining good health, supporting immune function, and promoting healthy skin and vision.

      Our chicken liver is perfect for making traditional dishes like pâté, liver stew, or simply sautéed with onions and herbs. Its rich flavor and smooth texture make it a versatile ingredient that can enhance a variety of recipes.

      For the best in fresh, high-quality organ meat, choose our 1kg pack of chicken liver. Perfect for home cooks and chefs, this liver will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium chicken liver with a rich, nutrient-dense taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in traditional and contemporary dishes
      ⁃ Enhances the flavor and nutrition of your meals
    `,
  },
  {
    product_id: "tb-ds43",
    product_image: product43,
    product_title: "Frozen: Chicken Wings - 1kg",
    product_price: "7400",
    category_name: "Frozen Foods",
    product_description: `
      Savor the juicy, flavorful taste of our premium Frozen Chicken Wings, available in a convenient 1kg pack. These high-quality wings are perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Chicken wings are an excellent source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our frozen chicken wings are perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, these wings will add a delicious and satisfying flavor to your dishes.

      For the best in fresh, high-quality meat, choose our 1kg pack of frozen chicken wings. Perfect for home cooks and chefs, these wings will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium frozen chicken wings for a juicy, flavorful taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in grilling, baking, or frying
      ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "tb-ds1044",
    product_image: product44,
    product_title: "Frozen: Chicken Drumstick - 1kg",
    product_price: "5990",
    category_name: "Frozen Foods",
    product_description: `
      Enjoy the tender and succulent taste of our premium Frozen Chicken Drumsticks, available in a convenient 1kg pack. These high-quality drumsticks are perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Chicken drumsticks are an excellent source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our frozen chicken drumsticks are perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, these drumsticks will add a delicious and satisfying flavor to your dishes.

      For the best in fresh, high-quality meat, choose our 1kg pack of frozen chicken drumsticks. Perfect for home cooks and chefs, these drumsticks will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium frozen chicken drumsticks for a tender and succulent taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in grilling, baking, or frying
      ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "19tb-ds",
    product_image: product45,
    product_title: "Frozen: Croaker x 1",
    product_price: "6,000.00",
    category_name: "Frozen Foods",
    product_description: `
      Experience the rich, flavorful taste of our premium Frozen Croaker, available as a whole fish. This high-quality fish is perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Croaker is an excellent source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as omega-3 fatty acids, vitamin D, and selenium, which support overall health and well-being.

      Our frozen croaker is perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, this fish will add a delicious and satisfying flavor to your dishes.

      For the best in fresh, high-quality seafood, choose our whole frozen croaker. Perfect for home cooks and chefs, this fish will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium frozen croaker for a rich, flavorful taste
      ⁃ High-quality protein source, rich in omega-3 fatty acids and vitamins
      ⁃ Versatile use in grilling, baking, or frying
      ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "tb-ds2r",
    product_image: product46,
    product_title: "Frozen: Turkey Wings - 1kg",
    product_price: "10000",
    category_name: "Frozen Foods",
    product_description: `
      Savor the juicy, flavorful taste of our premium Frozen Turkey Wings, available in a convenient 1kg pack. These high-quality wings are perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Turkey wings are an excellent source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.
      
        Our frozen turkey wings are perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, these wings will add a delicious and satisfying flavor to your dishes.

  For the best in fresh, high-quality meat, choose our 1kg pack of frozen turkey wings. Perfect for home cooks and chefs, these wings will help you create delicious and nutritious meals that everyone will love.

  Key Features:
  ⁃ Premium frozen turkey wings for a juicy, flavorful taste
  ⁃ High-quality protein source, rich in vitamins and minerals
  ⁃ Versatile use in grilling, baking, or frying
  ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "tb-ds35g",
    product_image: product47,
    product_title: "Frozen: Titus Fish",
    product_price: "3,200.00",
    category_name: "Frozen Foods",
    product_description: `
      Enjoy the rich, savory taste of our premium Frozen Titus Fish, perfect for a variety of dishes. This high-quality fish is known for its flavorful and versatile nature, making it a favorite in many cuisines.

      Titus fish is an excellent source of high-quality protein, essential for muscle growth and repair. It is also rich in important vitamins and minerals such as omega-3 fatty acids, vitamin D, and selenium, which support overall health and well-being.

      Our frozen titus fish is perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, this fish will add a delicious and satisfying flavor to your dishes.

      For the best in fresh, high-quality seafood, choose our frozen titus fish. Perfect for home cooks and chefs, this fish will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium frozen titus fish for a rich, savory taste
      ⁃ High-quality protein source, rich in omega-3 fatty acids and vitamins
      ⁃ Versatile use in grilling, baking, or frying
      ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "tb-ds42f",
    product_image: product48,
    product_title: "Frozen: Chicken Laps - 1 kg",
    product_price: "6000",
    category_name: "Frozen Foods",
    product_description: `
      Enjoy the tender and flavorful taste of our premium Frozen Chicken Laps, available in a convenient 1kg pack. These high-quality chicken pieces are perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Chicken laps are an excellent source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as iron, zinc, and vitamin B12, which support overall health and well-being.

      Our frozen chicken laps are perfect for grilling, baking, or frying. Whether you're preparing a simple family meal or a special occasion, these chicken pieces will add a delicious and satisfying flavor to your dishes.

      For the best in fresh, high-quality meat, choose our 1kg pack of frozen chicken laps. Perfect for home cooks and chefs, these chicken pieces will help you create delicious and nutritious meals that everyone will love.

      Key Features:
      ⁃ Premium frozen chicken laps for a tender and flavorful taste
      ⁃ High-quality protein source, rich in vitamins and minerals
      ⁃ Versatile use in grilling, baking, or frying
      ⁃ Enhances the flavor and satisfaction of your meals
    `,
  },
  {
    product_id: "tb-ds25g",
    product_image: product37,
    product_title: "Indomie Instant Noodles Chicken 70g x40",
    product_price: "11,300.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Indulge in the convenience and delicious taste of Indomie Instant Noodles Chicken flavor, available in a bulk pack of 70g x 40. Perfect for quick meals and snacks, these noodles are a household favorite.

      Indomie Instant Noodles are known for their rich chicken flavor, satisfying texture, and ease of preparation. They are a great source of carbohydrates and can be enhanced with additional vegetables, meats, or eggs for a more balanced meal.

      Our bulk pack ensures that you always have a quick and tasty meal option available, whether for busy days, quick snacks, or satisfying a craving. Simply cook the noodles in boiling water, add the seasoning, and enjoy a delicious meal in minutes.

      For a convenient and tasty meal option, choose Indomie Instant Noodles Chicken flavor in a bulk pack of 70g x 40. Perfect for families, students, and anyone who loves quick and flavorful noodles.

      Key Features:
      ⁃ Rich chicken flavor and satisfying texture
      ⁃ Quick and easy to prepare, ready in minutes
      ⁃ Great source of carbohydrates, can be enhanced with additional ingredients
      ⁃ Bulk pack of 70g x 40 for convenience and value
    `,
  },
  {
    product_id: "tb-ds5j",
    product_image: product38,
    product_title: "Golden Penny Spaghetti 500g",
    product_price: "1,200.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Enjoy the quality and taste of Golden Penny Spaghetti, available in a 500g pack. This premium spaghetti is perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Golden Penny Spaghetti is made from high-quality wheat, ensuring a firm texture and excellent cooking performance. It is ideal for traditional Italian pasta dishes, as well as a wide range of other recipes.

      Whether you're making a simple pasta with tomato sauce, a hearty spaghetti Bolognese, or a creative stir-fry, Golden Penny Spaghetti will help you create delicious and satisfying meals.

      Key Features:
      ⁃ Premium quality spaghetti made from high-quality wheat
      ⁃ Firm texture and excellent cooking performance
      ⁃ Versatile use in a variety of pasta dishes
      ⁃ 500g pack for convenience
    `,
  },
  {
    product_id: "tb-ds6ik",
    product_image: product39,
    product_title: "Barilla Linguine 500g",
    product_price: "5,330.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Savor the authentic taste of Barilla Linguine, available in a 500g pack. This high-quality pasta is perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Barilla Linguine is made from the finest durum wheat semolina, ensuring a firm texture and excellent cooking performance. It is ideal for traditional Italian pasta dishes, as well as a wide range of other recipes.

      Whether you're making a classic linguine with clam sauce, a rich Alfredo, or a creative seafood pasta, Barilla Linguine will help you create delicious and satisfying meals.

      Key Features:
      ⁃ Premium quality linguine made from durum wheat semolina
      ⁃ Firm texture and excellent cooking performance
      ⁃ Versatile use in a variety of pasta dishes
      ⁃ 500g pack for convenience
    `,
  },
  {
    product_id: "tb-ds3u",
    product_image: product40,
    product_title: "Golden Penny Macaroni 500g",
    product_price: "1,400.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Enjoy the quality and taste of Golden Penny Macaroni, available in a 500g pack. This premium macaroni is perfect for a variety of dishes, offering a delicious and versatile option for your meals.

      Golden Penny Macaroni is made from high-quality wheat, ensuring a firm texture and excellent cooking performance. It is ideal for traditional macaroni dishes, as well as a wide range of other recipes.

      Whether you're making a classic macaroni and cheese, a hearty macaroni salad, or a creative pasta bake, Golden Penny Macaroni will help you create delicious and satisfying meals.

      Key Features:
      ⁃ Premium quality macaroni made from high-quality wheat
      ⁃ Firm texture and excellent cooking performance
      ⁃ Versatile use in a variety of pasta dishes
      ⁃ 500g pack for convenience
    `,
  },
  {
    product_id: "tb-ds9qf",
    product_image: product41,
    product_title: "Indomie Instant Noodles Chicken 70g",
    product_price: "265.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Indulge in the convenience and delicious taste of Indomie Instant Noodles Chicken flavor, available in a 70g pack. Perfect for quick meals and snacks, these noodles are a household favorite.

      Indomie Instant Noodles are known for their rich chicken flavor, satisfying texture, and ease of preparation. They are a great source of carbohydrates and can be enhanced with additional vegetables, meats, or eggs for a more balanced meal.

      Simply cook the noodles in boiling water, add the seasoning, and enjoy a delicious meal in minutes. For a convenient and tasty meal option, choose Indomie Instant Noodles Chicken flavor.

      Key Features:
      ⁃ Rich chicken flavor and satisfying texture
      ⁃ Quick and easy to prepare, ready in minutes
      ⁃ Great source of carbohydrates, can be enhanced with additional ingredients
      ⁃ 70g pack for convenience
    `,
  },
  {
    product_id: "tb-ds3t",
    product_image: product42,
    product_title: "Dinor Mushroom Pieces & Stems 400g",
    product_price: "1,700.00",
    category_name: "Canned & Packaged Foods",
    product_description: `
      Enhance your meals with the rich flavor and texture of Dinor Mushroom Pieces & Stems, available in a 400g can. These high-quality mushrooms are perfect for a variety of dishes, offering a delicious and versatile option for your recipes.

      Dinor Mushroom Pieces & Stems are carefully selected and packed to ensure the best quality and taste. They are a great source of vitamins and minerals, adding both nutrition and flavor to your meals.

      Whether you're making soups, stews, sauces, or stir-fries, Dinor Mushroom Pieces & Stems will help you create delicious and satisfying dishes.

      Key Features:
      ⁃ High-quality mushroom pieces and stems for a rich flavor and texture
      ⁃ Great source of vitamins and minerals
      ⁃ Versatile use in a variety of dishes
      ⁃ 400g can for convenience
    `,
  },
  {
    product_id: "tb-ds5j",
    product_image: product19,
    product_title: "Funtuna Eggs x30",
    product_price: "8,000.00",
    category_name: "Dairy & Eggs",
    product_description: `
      Enjoy the freshness and quality of Funtuna Eggs, available in a pack of 30. These high-quality eggs are perfect for a variety of dishes, offering a nutritious and versatile option for your meals.

      Funtuna Eggs are a great source of high-quality protein, essential for muscle growth and repair. They are also rich in important vitamins and minerals such as vitamin D, vitamin B12, and selenium, which support overall health and well-being.

      Whether you're making breakfast, baking, or preparing a meal, Funtuna Eggs will help you create delicious and nutritious dishes.

      Key Features:
      ⁃ High-quality eggs for freshness and quality
      ⁃ Great source of protein, vitamins, and minerals
      ⁃ Versatile use in a variety of dishes
      ⁃ Pack of 30 for convenience
    `,
  },
  {
    product_id: "tb-ds2p",
    product_image: product20,
    product_title: "Peak Instant Full Cream Milk Powder Sachet 850g",
    product_price: "9,400.00",
    category_name: "Dairy & Eggs",
    product_description: `
    Enjoy the rich, creamy taste of Peak Instant Full Cream Milk Powder in a convenient 850g sachet. Perfect for baking, cooking, or as a delicious drink, this milk powder provides essential nutrients such as calcium, protein, and vitamins A and D. Ideal for both home use and professional kitchens.

    Peak Instant Full Cream Milk Powder is crafted to deliver superior taste and nutrition. The powder dissolves quickly and completely in both hot and cold water, making it a versatile addition to your pantry. Use it to enhance the creaminess of your tea, coffee, smoothies, and desserts, or incorporate it into your baking recipes for a richer texture and flavor.

    The long shelf life of this milk powder makes it a practical choice for those looking to reduce waste and ensure they always have high-quality milk on hand. Whether you're preparing a quick breakfast, a nutritious snack, or an elaborate meal, Peak Instant Full Cream Milk Powder is a reliable and delicious choice.

    Key Features:
    ⁃ Rich and creamy taste
    ⁃ Provides essential nutrients: calcium, protein, vitamins A and D
    ⁃ Dissolves quickly in hot and cold water
    ⁃ Long shelf life
    ⁃ Versatile use in drinks, cooking, and baking
  `,
  },
  {
    product_id: "tb-ds1b",
    product_image: product21,
    product_title: "Tuvee Coconut Milk 400ml",
    product_price: "1,870.00",
    category_name: "Dairy & Eggs",
    product_description: `
    Add a tropical twist to your dishes with Tuvee Coconut Milk. This 400ml can of rich and creamy coconut milk is perfect for curries, desserts, smoothies, and more. Packed with natural goodness and a distinct coconut flavor, it's a must-have for any kitchen.

    Tuvee Coconut Milk is made from high-quality coconuts, ensuring a pure and authentic taste. It is an excellent source of healthy fats, which provide energy and support overall wellness. The creamy texture and sweet, nutty flavor make it a versatile ingredient for both sweet and savory recipes.

    Use Tuvee Coconut Milk to create delicious curries, soups, and sauces with a tropical flair. It's also great for baking, adding a unique flavor to cakes, cookies, and other desserts. For a refreshing beverage, blend it into smoothies or use it as a dairy-free creamer in your coffee.

    Key Features:
    ⁃ Made from high-quality coconuts
    ⁃ Rich and creamy texture
    ⁃ Distinct coconut flavor
    ⁃ Source of healthy fats
    ⁃ Versatile ingredient for sweet and savory recipes
  `,
  },
  {
    product_id: "tb-ds5t",
    product_image: product22,
    product_title:
      "Kirkland Unsweetened Almond Non-Dairy Beverage Vanilla 946ml",
    product_price: "5,945.00",
    category_name: "Dairy & Eggs",
    product_description: `
    Enjoy a delicious, dairy-free alternative with Kirkland Unsweetened Almond Non-Dairy Beverage in Vanilla flavor. This 946ml carton is perfect for those seeking a lactose-free, low-calorie option. Great for cereals, coffee, smoothies, and baking, it offers a smooth, nutty flavor.

    Kirkland Unsweetened Almond Beverage is crafted from high-quality almonds, providing a rich source of vitamins and minerals, including vitamin E and calcium. The subtle vanilla flavor adds a touch of sweetness without the need for added sugars, making it a healthier choice for your daily diet.

    Whether you're lactose intolerant, vegan, or simply looking to reduce your dairy intake, this almond beverage is a versatile and nutritious option. Use it in your morning cereal, blend it into smoothies, or add it to your coffee for a creamy, dairy-free experience. It’s also perfect for baking, adding moisture and flavor to your favorite recipes.

    Key Features:
    ⁃ Dairy-free and lactose-free
    ⁃ Low in calories
    ⁃ Subtle vanilla flavor with no added sugars
    ⁃ Rich source of vitamins E and calcium
    ⁃ Versatile use in cereals, coffee, smoothies, and baking
  `,
  },
  {
    product_id: "tb-ds6h",
    product_image: product23,
    product_title: "Hollandia Lactose Free Milk 1L",
    product_price: "3,000.00",
    category_name: "Dairy & Eggs",
    product_description: `
    Hollandia Lactose Free Milk offers a smooth, creamy taste without the lactose. This 1L carton is ideal for those with lactose intolerance, providing all the benefits of regular milk, including essential vitamins and minerals. Perfect for drinking, cooking, and baking.

    Hollandia Lactose Free Milk is formulated to be gentle on your digestive system while delivering the rich, creamy taste you love. It’s an excellent source of calcium, vitamin D, and other essential nutrients that support bone health and overall wellness.

    Enjoy Hollandia Lactose Free Milk in your morning coffee, cereal, or simply as a refreshing drink on its own. It’s also a great addition to your cooking and baking recipes, adding a creamy texture and delicious flavor without the discomfort of lactose.

    Key Features:
    ⁃ Lactose-free and easy to digest
    ⁃ Rich and creamy taste
    ⁃ Excellent source of calcium and vitamin D
    ⁃ Versatile use in drinks, cooking, and baking
    ⁃ Supports bone health and overall wellness
  `,
  },
  {
    product_id: "tb-ds2r",
    product_image: product24,
    product_title: "Dano UHT Full Cream Milk 1L",
    product_price: "3,999.00",
    category_name: "Dairy & Eggs",
    product_description: `
    Experience the creamy goodness of Dano UHT Full Cream Milk in a 1L carton. Ultra-High Temperature (UHT) processed for long shelf life, this milk retains its rich flavor and nutritional benefits. Ideal for drinking, cooking, and baking, it’s a staple in every kitchen.

    Dano UHT Full Cream Milk is known for its rich, creamy texture and high nutritional value. It provides essential nutrients such as calcium, protein, and vitamins A and D, supporting your overall health and well-being. The UHT process ensures that the milk stays fresh for longer without the need for refrigeration until opened.

    Use Dano UHT Full Cream Milk to enhance your morning cereal, coffee, or tea. It’s also perfect for cooking and baking, adding a creamy texture and rich flavor to your dishes. Whether you're making sauces, soups, or desserts, Dano UHT Full Cream Milk is a versatile and reliable choice.

    Key Features:
    ⁃ UHT processed for long shelf life
    ⁃ Rich and creamy texture
    ⁃ High in calcium, protein, and vitamins A and D
    ⁃ Versatile use in drinks, cooking, and baking
    ⁃ No refrigeration needed until opened
  `,
  },
  {
    product_id: "tb-ds1v",
    product_image: product25,
    product_title:
      "Gatorade Thirst Quencher, Cool Blue, 12 Ounce Bottles (Pack of 24)",
    product_price: "149,000.00",
    category_name: "Beverages",
    product_description: `
    Stay hydrated and energized with Gatorade Thirst Quencher in Cool Blue flavor. This pack of 24 bottles, each 12 ounces, is perfect for athletes and active individuals. Packed with electrolytes and carbohydrates, Gatorade helps replenish what you lose in sweat, keeping you at your best.

    Gatorade Thirst Quencher is scientifically formulated to help you perform at your peak. The balanced blend of electrolytes, including sodium and potassium, helps maintain proper hydration levels, while the carbohydrates provide quick energy to fuel your workouts.

    Whether you're hitting the gym, playing sports, or just staying active, Gatorade Thirst Quencher is your go-to beverage for hydration and energy. The refreshing Cool Blue flavor makes it easy to stay hydrated throughout the day.

    Key Features:
    ⁃ Scientifically formulated for hydration and energy
    ⁃ Packed with electrolytes and carbohydrates
    ⁃ Refreshing Cool Blue flavor
    ⁃ Ideal for athletes and active individuals
    ⁃ Convenient pack of 24 bottles
  `,
  },
  {
    product_id: "tb-ds67h",
    product_image: product26,
    product_title: "Nestle Pure Life Water 60cl x20",
    product_price: "3,950.00",
    category_name: "Beverages",
    product_description: `
    Enjoy clean, refreshing hydration with Nestle Pure Life Water. This pack of 20 bottles, each 60cl, is perfect for home, office, or on-the-go. Sourced and purified for quality, Nestle Pure Life ensures you stay hydrated and healthy every day.

    Nestle Pure Life Water undergoes a rigorous purification process to ensure the highest quality. Each bottle is designed for convenience and portability, making it easy to stay hydrated wherever you are. Whether you're at home, at work, or on the move, Nestle Pure Life is your reliable source of clean, refreshing water.

    Drinking water is essential for maintaining overall health and wellness. Nestle Pure Life Water provides the pure, crisp taste you crave, without any added flavors or preservatives. It’s perfect for quenching your thirst and staying hydrated throughout the day.

    Key Features:
    ⁃ Clean and refreshing hydration
    ⁃ Rigorous purification process
    ⁃ Convenient and portable 60cl bottles
    ⁃ Pack of 20 bottles
    ⁃ Essential for maintaining health and wellness
  `,
  },
  {
    product_id: "tb-ds3hg",
    product_image: product27,
    product_title: "CWAY Refill Water",
    product_price: "1,600.00",
    category_name: "Beverages",
    product_description: `
    Enjoy the convenience and purity of CWAY Refill Water. This water is sourced and purified to the highest standards, ensuring a fresh and clean taste every time. Perfect for home or office use, CWAY Refill Water is an essential part of your daily hydration needs.

    CWAY Refill Water undergoes a multi-stage purification process to remove impurities, providing you with water that is both safe and refreshing. Its convenient refill system makes it easy to maintain a continuous supply of water without the need for frequent trips to the store.

    Whether you're using it for drinking, cooking, or making beverages, CWAY Refill Water is a reliable choice for high-quality hydration. Stay hydrated and healthy with CWAY, a brand you can trust for purity and taste.

    Key Features:
    ⁃ Multi-stage purification process
    ⁃ Fresh and clean taste
    ⁃ Convenient refill system
    ⁃ Perfect for home or office use
    ⁃ Essential for daily hydration
  `,
  },
  {
    product_id: "tb-ds24r",
    product_image: product28,
    product_title: "Smoov Chapman Pet Bottle 35cl",
    product_price: "349.00",
    category_name: "Beverages",
    product_description: `
    Enjoy the refreshing taste of Smoov Chapman in a convenient 35cl PET bottle. This delightful beverage combines the flavors of citrus fruits, bitters, and a hint of grenadine, making it a popular choice for parties and gatherings.

    Smoov Chapman is a refreshing non-alcoholic drink that is perfect for any occasion. Its unique blend of flavors provides a delightful and invigorating taste experience. Whether you're enjoying it on a hot day or serving it at a party, Smoov Chapman is sure to be a hit with everyone.

    The 35cl PET bottle is easy to carry, making it ideal for on-the-go refreshment. Enjoy the vibrant and fruity taste of Smoov Chapman anytime, anywhere.

    Key Features:
    ⁃ Refreshing citrus and bitters flavor
    ⁃ Non-alcoholic beverage
    ⁃ Convenient 35cl PET bottle
    ⁃ Perfect for parties and gatherings
    ⁃ Ideal for on-the-go refreshment
  `,
  },
  {
    product_id: "tb-ds6u",
    product_image: product29,
    product_title: "Coca Cola Coke Pet Bottle 50cl x12",
    product_price: "4,900.00",
    category_name: "Beverages",
    product_description: `
    Enjoy the classic taste of Coca Cola in a convenient 50cl PET bottle. This pack of 12 bottles is perfect for sharing with family and friends. Coca Cola is the world's favorite soft drink, known for its refreshing and invigorating taste.

    Coca Cola is made from a unique blend of ingredients that provide a crisp and refreshing flavor. It's the perfect beverage to enjoy with meals, at parties, or as a refreshing drink on a hot day. The 50cl PET bottle is easy to carry and ideal for on-the-go enjoyment.

    With Coca Cola, you can always expect the highest quality and consistency. Whether you're at home, at work, or on the move, Coca Cola is the perfect companion for refreshment.

    Key Features:
    ⁃ Classic Coca Cola taste
    ⁃ Convenient 50cl PET bottle
    ⁃ Pack of 12 bottles
    ⁃ Perfect for sharing
    ⁃ Ideal for on-the-go refreshment
  `,
  },
  {
    product_id: "tb-ds5y",
    product_image: product30,
    product_title: "Ceres Sparkling Apple Juice 27.5cl x24",
    product_price: "55,000.00",
    category_name: "Beverages",
    product_description: `
    Experience the crisp and refreshing taste of Ceres Sparkling Apple Juice. This pack of 24 bottles, each 27.5cl, offers a delicious and bubbly apple juice that is perfect for any occasion. Made from 100% pure apple juice with no added sugars, it’s a healthy and tasty choice.

    Ceres Sparkling Apple Juice is made from the finest apples, providing a natural and authentic flavor. The sparkling bubbles add a fun and refreshing twist to the classic apple juice, making it a great choice for parties, picnics, or simply enjoying at home.

    Enjoy the natural sweetness and effervescence of Ceres Sparkling Apple Juice. It’s a delightful beverage that both kids and adults will love.

    Key Features:
    ⁃ 100% pure apple juice
    ⁃ No added sugars
    ⁃ Crisp and refreshing taste
    ⁃ Sparkling bubbles for a fun twist
    ⁃ Pack of 24 bottles
  `,
  },
  {
    product_id: "tb-ds64g",
    product_image: product31,
    product_title: "Tostitos Scoops Tortilla Chips 10oz Bag",
    product_price: "9,899.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Enjoy the perfect snack with Tostitos Scoops Tortilla Chips. This 10oz bag of crunchy and delicious tortilla chips is ideal for dipping into your favorite salsa, guacamole, or queso. The unique scoop shape makes it easy to get the perfect bite every time.

    Tostitos Scoops Tortilla Chips are made from high-quality corn and are perfectly seasoned to enhance their natural flavor. They are a great choice for parties, picnics, or simply enjoying at home as a tasty snack.

    Pair Tostitos Scoops with your favorite dip for an unbeatable combination of taste and texture. These chips are sure to be a hit with friends and family.

    Key Features:
    ⁃ Unique scoop shape for easy dipping
    ⁃ Made from high-quality corn
    ⁃ Perfectly seasoned
    ⁃ Ideal for parties and picnics
    ⁃ 10oz bag
  `,
  },
  {
    product_id: "tb-ds77u",
    product_image: product32,
    product_title: "Crownfield Granola Raisin & Almond 1kg",
    product_price: "10,225.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Start your day with the wholesome and delicious taste of Crownfield Granola Raisin & Almond. This 1kg pack of granola is packed with crunchy clusters, juicy raisins, and crunchy almonds, providing a nutritious and satisfying breakfast or snack.

    Crownfield Granola is made from whole grain oats and is a great source of fiber, protein, and essential nutrients. The combination of raisins and almonds adds natural sweetness and a delightful crunch, making it a perfect addition to your morning routine.

    Enjoy Crownfield Granola with milk, yogurt, or as a topping for your favorite smoothie bowl. It’s a delicious and healthy way to start your day.

    Key Features:
    ⁃ Made from whole grain oats
    ⁃ Packed with raisins and almonds
    ⁃ Great source of fiber and protein
    ⁃ Nutritious and satisfying
    ⁃ 1kg pack
  `,
  },
  {
    product_id: "tb-ds23o",
    product_image: product33,
    product_title: "Fun Snax Cheese Balls Party Pack 100g",
    product_price: "1,600.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Enjoy the cheesy goodness of Fun Snax Cheese Balls. This 100g party pack is perfect for sharing with friends and family. These light and crispy cheese balls are bursting with flavor, making them an irresistible snack for any occasion.

    Fun Snax Cheese Balls are made from high-quality ingredients and are perfectly seasoned to deliver a delicious cheesy taste. They are ideal for parties, picnics, or simply enjoying as a snack at home.

    Treat yourself to the delightful taste and crunch of Fun Snax Cheese Balls. They are sure to be a hit with everyone.

    Key Features:
    ⁃ Light and crispy texture
    ⁃ Bursting with cheesy flavor
    ⁃ Made from high-quality ingredients
    ⁃ Perfect for sharing
    ⁃ 100g party pack
  `,
  },
  {
    product_id: "tb-ds6n",
    product_image: product34,
    product_title: "Pringles Original 165g",
    product_price: "5,615.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Enjoy the classic taste of Pringles Original. This 165g can of perfectly stacked potato crisps is a timeless snack that never goes out of style. Each crisp is uniformly shaped and seasoned to perfection, providing a satisfying crunch with every bite.

    Pringles Original are made from high-quality potatoes and are lightly salted to enhance their natural flavor. They are perfect for snacking on their own or enjoying with your favorite dip.

    The iconic Pringles canister keeps the crisps fresh and intact, making it easy to take them on the go. Enjoy the delicious taste and crunch of Pringles Original anytime, anywhere.

    Key Features:
    ⁃ Classic potato crisp flavor
    ⁃ Perfectly stacked and seasoned
    ⁃ Made from high-quality potatoes
    ⁃ Lightly salted for natural flavor
    ⁃ 165g canister
  `,
  },
  {
    product_id: "tb-ds6bo",
    product_image: product35,
    product_title: "McVitie's All Butter Shortbread 200g",
    product_price: "2,900.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Indulge in the rich and buttery taste of McVitie's All Butter Shortbread. This 200g pack of traditional Scottish shortbread is made with all butter, providing a melt-in-the-mouth texture and a deliciously rich flavor.

    McVitie's All Butter Shortbread is crafted from a classic recipe, using the finest ingredients to ensure the highest quality. These shortbread biscuits are perfect for enjoying with a cup of tea or coffee, or as a delightful treat on their own.

    Treat yourself to the luxurious taste of McVitie's All Butter Shortbread. They are a timeless favorite that everyone will love.

    Key Features:
    ⁃ Rich and buttery flavor
    ⁃ Made with all butter
    ⁃ Traditional Scottish recipe
    ⁃ Perfect for enjoying with tea or coffee
    ⁃ 200g pack
  `,
  },
  {
    product_id: "tb-ds3ro",
    product_image: product36,
    product_title: "Primara Plantain Chips - Semi Ripe (Medium Jar)",
    product_price: "3,000.00",
    category_name: "Snacks & Confectionery",
    product_description: `
    Enjoy the delicious taste of Primara Plantain Chips - Semi Ripe. This medium jar is packed with crispy and flavorful plantain chips, made from semi-ripe plantains for the perfect balance of sweetness and crunch.

    Primara Plantain Chips are a healthy and tasty snack option, made from natural ingredients without any artificial additives. They are perfect for snacking on their own or serving at parties and gatherings.

    Indulge in the unique and satisfying taste of Primara Plantain Chips. They are a great alternative to traditional potato chips and are sure to be a hit with everyone.

    Key Features:
    ⁃ Made from semi-ripe plantains
    ⁃ Crispy and flavorful
    ⁃ Natural ingredients, no artificial additives
    ⁃ Healthy and tasty snack option
    ⁃ Medium jar
  `,
  },
  {
    product_id: "tb-ds45t",
    product_image: product49,
    product_title: "Knorr Chicken Cubes 8g",
    product_price: "1,700.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Enhance the flavor of your dishes with Knorr Chicken Cubes. This pack contains 8g cubes of rich and savory chicken flavor, perfect for adding depth and taste to your soups, stews, sauces, and more.

    Knorr Chicken Cubes are made from high-quality ingredients and are carefully blended to deliver a consistent and delicious flavor. They dissolve quickly and evenly, making it easy to incorporate them into your cooking.

    Whether you're preparing a quick meal or a gourmet dish, Knorr Chicken Cubes are a convenient and reliable choice for adding a burst of savory goodness.

    Key Features:
    ⁃ Rich and savory chicken flavor
    ⁃ Made from high-quality ingredients
    ⁃ Dissolves quickly and evenly
    ⁃ Perfect for soups, stews, sauces, and more
    ⁃ 8g cubes
  `,
  },
  {
    product_id: "tb-ds4oh",
    product_image: product50,
    product_title:
      "Frontier Organic Fair Trade Ground Ceylon Cinnamon 5.57oz 158g",
    product_price: "20,500.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Discover the rich and aromatic flavor of Frontier Organic Fair Trade Ground Ceylon Cinnamon. This 5.57oz (158g) jar contains premium quality Ceylon cinnamon, known for its delicate and sweet flavor profile.

    Frontier Organic Ceylon Cinnamon is sourced from fair trade farms, ensuring that the farmers receive a fair price for their high-quality produce. It is also certified organic, making it a healthy and sustainable choice for your cooking and baking needs.

    Use Frontier Organic Ceylon Cinnamon to add warmth and depth to your favorite recipes, from desserts and baked goods to savory dishes and beverages. Its unique flavor will elevate your culinary creations to new heights.

    Key Features:
    ⁃ Premium quality Ceylon cinnamon
    ⁃ Delicate and sweet flavor profile
    ⁃ Fair trade and organic certified
    ⁃ Ideal for cooking and baking
    ⁃ 5.57oz (158g) jar
  `,
  },
  {
    product_id: "tb-ds2ff",
    product_image: product51,
    product_title: "Tiger Nutmeg Powder 100g",
    product_price: "2,680.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Add a touch of warmth and spice to your dishes with Tiger Nutmeg Powder. This 100g jar contains finely ground nutmeg, perfect for enhancing the flavor of both sweet and savory recipes.

    Tiger Nutmeg Powder is made from high-quality nutmeg seeds, carefully selected and ground to preserve their natural aroma and taste. It is a versatile spice that can be used in a variety of dishes, from baked goods and desserts to meats and vegetables.

    Enjoy the rich and aromatic flavor of Tiger Nutmeg Powder in your cooking. It is a must-have spice for any kitchen.

    Key Features:
    ⁃ Finely ground nutmeg
    ⁃ Made from high-quality nutmeg seeds
    ⁃ Rich and aromatic flavor
    ⁃ Versatile spice for sweet and savory dishes
    ⁃ 100g jar
  `,
  },
  {
    product_id: "tb-ds4gz",
    product_image: product52,
    product_title: "Watkins Gourmet Organic Spice Jar, Curry Powder, 2.6Oz",
    product_price: "7,000.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Elevate your cooking with Watkins Gourmet Organic Curry Powder. This 2.6oz jar contains a premium blend of organic spices, carefully selected and blended to create a rich and flavorful curry powder.

    Watkins Gourmet Curry Powder is certified organic and free from artificial additives, making it a healthy and natural choice for your kitchen. Its balanced and aromatic blend is perfect for adding depth and warmth to a variety of dishes, from curries and stews to soups and marinades.

    Experience the authentic taste of Watkins Gourmet Organic Curry Powder in your cooking. It is a must-have spice for any culinary enthusiast.

    Key Features:
    ⁃ Premium blend of organic spices
    ⁃ Rich and flavorful curry powder
    ⁃ Certified organic and free from artificial additives
    ⁃ Ideal for curries, stews, soups, and marinades
    ⁃ 2.6oz jar
  `,
  },
  {
    product_id: "tb-ds21d",
    product_image: product53,
    product_title: "JADA Spices Turmeric Salt Spice All Seasoning X 3 93g Each",
    product_price: "13,000.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Enhance your dishes with the unique flavor of JADA Spices Turmeric Salt. This pack includes three 93g jars of all-purpose seasoning, combining the earthy taste of turmeric with the savory goodness of salt.

    JADA Spices Turmeric Salt is made from high-quality ingredients and is perfect for adding a burst of flavor to a wide range of dishes. Use it as a seasoning for meats, vegetables, soups, and more. It is also a great way to incorporate the health benefits of turmeric into your diet.

    Enjoy the vibrant and flavorful taste of JADA Spices Turmeric Salt in your cooking. It is a versatile and delicious addition to any kitchen.

    Key Features:
    ⁃ Unique blend of turmeric and salt
    ⁃ High-quality ingredients
    ⁃ Perfect for seasoning a variety of dishes
    ⁃ Includes three 93g jars
    ⁃ Adds flavor and health benefits to your meals
  `,
  },
  {
    product_id: "tb-ds35t",
    product_image: product54,
    product_title: "Mccormick Ground Cinnamon, 18Oz 510g",
    product_price: "17,000.00",
    category_name: "Condiments & Sauces",
    product_description: `
    Enjoy the rich and aromatic flavor of Mccormick Ground Cinnamon. This 18oz (510g) jar contains premium quality ground cinnamon, perfect for adding warmth and depth to your favorite recipes.

    Mccormick Ground Cinnamon is made from high-quality cinnamon bark, carefully selected and ground to preserve its natural aroma and taste. It is a versatile spice that can be used in a variety of dishes, from baked goods and desserts to savory dishes and beverages.

    Experience the rich and aromatic flavor of Mccormick Ground Cinnamon in your cooking. It is a must-have spice for any kitchen.

    Key Features:
    ⁃ Premium quality ground cinnamon
    ⁃ Rich and aromatic flavor
    ⁃ Made from high-quality cinnamon bark
    ⁃ Versatile spice for sweet and savory dishes
    ⁃ 18oz (510g) jar
  `,
  },
];
