import React from "react";
import { useTitle } from "../../hooks/useTitle";
import "./Shop.css";
import "../../Components/Boot/Boot.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import MobileSearch from "../../Components/Mobile/MobileSearch";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function Shop() {
  useTitle("Shop - TrendBay");

  return (
    <div>
      <MobileSearch />
      <Navbar />
      <div className="shop_container">
        <div className="container shop_max">
          <h5 className="ml-3">Shop by Category</h5>
          <span className="float-right text-success main_view-all mb-3">
            <a href="/shop/all">
              All Products
              <ArrowRightAltIcon />
            </a>
          </span>

          <a href="/shop/fresh-farm-produce">
            <button className="accordion">Fresh Farm Produce</button>
          </a>

          <a href="/shop/grains-and-staples">
            <button className="accordion">Grains and Staples</button>
          </a>

          <a href="/shop/poultry-and-meat">
            <button className="accordion">Poultry and Meat</button>
          </a>

          <a href="/shop/catfish">
            <button className="accordion">Catfish</button>
          </a>

          <a href="/shop/dairy-and-eggs">
            <button className="accordion">Dairy and Eggs</button>
          </a>

          <a href="/shop/beverages">
            <button className="accordion">Beverages</button>
          </a>

          <a href="/shop/snacks-and-confectionery">
            <button className="accordion">Snacks and Confectionery</button>
          </a>

          <a href="/shop/canned-and-packaged-foods">
            <button className="accordion">Canned and Packaged Foods</button>
          </a>

          <a href="/shop/frozen-foods">
            <button className="accordion">Frozen Foods</button>
          </a>

          <a href="/shop/condiments-and-sauces">
            <button className="accordion">Condiments and Sauces</button>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
