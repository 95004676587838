import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Product from "../Product/Product";
import { useTitle } from "../../hooks/useTitle";
import "./ShopCategory.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import MobileSearch from "../../Components/Mobile/MobileSearch";
import SideBar from "../Product/SideBar";
import Sorting from "../Shop/Sorting";
import Loading from "../../Loading";

function slugify(text) {
  if (!text) return "";
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/&/g, "and")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

function capitalizeTitle(text) {
  if (!text) return "";

  const exceptions = [
    "and",
    "the",
    "of",
    "in",
    "to",
    "with",
    "for",
    "on",
    "a",
    "an",
  ];
  return text
    .toLowerCase()
    .split(" ")
    .map((word, index) =>
      index === 0 || !exceptions.includes(word)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word
    )
    .join(" ");
}

export default function ShopCategory({ category }) {
  const url = window.location.pathname;
  const match = url.match(/\/shop\/([^/]+)/);
  const categoryName = match ? match[1].replace(/-/g, " ") : "all";

  const displayCategoryName = capitalizeTitle(categoryName);

  useTitle(`${displayCategoryName} - TrendBay DS`);

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sort, setSort] = useState("newest");
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter products by category
        let updatedProducts = productsData.filter(
          (product) => slugify(product.category_name) === slugify(categoryName)
        );

        // Apply filters
        if (filters.minPrice !== undefined) {
          updatedProducts = updatedProducts.filter(
            (product) => product.product_price >= filters.minPrice
          );
        }
        if (filters.maxPrice !== undefined) {
          updatedProducts = updatedProducts.filter(
            (product) => product.product_price <= filters.maxPrice
          );
        }
        if (filters.availability) {
          updatedProducts = updatedProducts.filter(
            (product) => product.availability === filters.availability
          );
        }

        // Apply sorting
        switch (sort) {
          case "price-asc":
            updatedProducts.sort((a, b) => a.product_price - b.product_price);
            break;
          case "price-desc":
            updatedProducts.sort((a, b) => b.product_price - a.product_price);
            break;
          case "popularity":
            updatedProducts.sort((a, b) => b.popularity - a.popularity);
            break;
          case "newest":
          default:
            updatedProducts.sort((a, b) => b.product_id - a.product_id);
            break;
        }

        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, [categoryName, sort, filters]);

  useEffect(() => {
    // Apply filters whenever filters or sort change
    const applyFilters = () => {
      let updatedProducts = [...products];

      // Apply filters
      if (filters.minPrice !== undefined) {
        updatedProducts = updatedProducts.filter(
          (product) => product.product_price >= filters.minPrice
        );
      }
      if (filters.maxPrice !== undefined) {
        updatedProducts = updatedProducts.filter(
          (product) => product.product_price <= filters.maxPrice
        );
      }
      if (filters.availability) {
        updatedProducts = updatedProducts.filter(
          (product) => product.availability === filters.availability
        );
      }

      setFilteredProducts(updatedProducts);
    };

    applyFilters();
  }, [filters, products, sort]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleSortChange = (sortOption) => {
    setSort(sortOption);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <MobileSearch />
      <Navbar />
      <div className="below_navbar align-items-sm-center">
        <div className="topnav-center">
          <a href="/">Home</a>
          <a href="/shop">Shop</a>
          <a href={`/shop/${slugify(category)}`} className="active">
            {displayCategoryName}
          </a>
        </div>
      </div>
      <div className="container mb-5">
        <div className="main-container">
          <SideBar onFilterChange={handleFilterChange} />
          <div className="all-products container">
            <div className="float-right mt-3 mb-4">
              <Sorting onSortChange={handleSortChange} />
            </div>
            <div className="all_product-card container">
              {currentProducts.length > 0 ? (
                currentProducts.map((product) => (
                  <Product
                    key={product.id}
                    product_id={product.id}
                    product_image={product.product_image}
                    product_title={product.product_title}
                    product_price={product.product_price}
                    product_description={product.product_description}
                    category_name={product.category_name}
                  />
                ))
              ) : (
                <Loading />
              )}
            </div>
            <div className="pagination">
              {Array.from(
                {
                  length: Math.ceil(filteredProducts.length / productsPerPage),
                },
                (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={`page-button ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
