export const initialState = {
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  user: null,
  shippingInfo: null,
  billingInfo: null,
};

export const getCartTotal = (cart) =>
  cart?.reduce((amount, item) => item.price * item.quantity + amount, 0) || 0;

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItemIndex = state.cart.findIndex(
        (item) => item.id === action.item.id
      );
      if (existingItemIndex >= 0) {
        const updatedCart = [...state.cart];
        updatedCart[existingItemIndex].quantity += 1;
        const updatedState = {
          ...state,
          cart: updatedCart,
        };
        localStorage.setItem("cart", JSON.stringify(updatedState.cart));
        return updatedState;
      } else {
        const newState = {
          ...state,
          cart: [
            ...state.cart,
            {
              id: action.item.id,
              title: action.item.title,
              image: action.item.image,
              price: action.item.price,
              quantity: 1,
            },
          ],
        };
        localStorage.setItem("cart", JSON.stringify(newState.cart));
        return newState;
      }

    case "REMOVE_FROM_CART":
      const index = state.cart.findIndex(
        (cartItem) => cartItem.id === action.id
      );
      let newCart = [...state.cart];

      if (index >= 0) {
        newCart.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(newCart));
      } else {
        console.warn(
          `Can't remove product (id: ${action.id}) as it's not in the cart!`
        );
      }

      return {
        ...state,
        cart: newCart,
      };

    case "UPDATE_QUANTITY":
      const updatedCart = state.cart.map((item) =>
        item.id === action.id ? { ...item, quantity: action.quantity } : item
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return {
        ...state,
        cart: updatedCart,
      };

    case "CLEAR_CART":
      localStorage.removeItem("cart");
      return {
        ...state,
        cart: [],
      };

    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    case "SET_SHIPPING_INFO":
      return {
        ...state,
        shippingInfo: action.shippingInfo,
      };

    case "SET_BILLING_INFO":
      return {
        ...state,
        billingInfo: action.billingInfo,
      };

    default:
      return state;
  }
};

export default reducer;
