import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import NotFound from "./Pages/NotFound/NotFound";
import Cart from "./Pages/Cart/Cart";
import Login from "./Pages/Auth/Login";
import SignUp from "./Pages/Auth/SignUp";
import ScrollToTop from "./ScrollToTop";
import OrderDetails from "./Pages/OrderDetails/OrderDetails";
import Shop from "./Pages/Shop/Shop";
import ShopCategory from "./Pages/Shop/ShopCategory";
import ProductDetails from "./Pages/Product/ProductDetails";
import AllProducts from "./Pages/Product/AllProducts";
import MobileFooter from "./Components/Mobile/MobileFooter";
import MobileWindow from "./Components/Mobile/MobileWindow";
import AccountPage from "./Pages/Auth/AccountPage";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Checkout from "./Pages/Cart/Checkout";
import ThankYou from "./Pages/Cart/ThankYou";
import OrderList from "./Pages/OrderDetails/OrderList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";
import BackToTop from "./BackToTop";
import LogVisitor from "./LogVisitor";
import VerifyEmail from "./Pages/Auth/VerifyEmail";
import LoadingScreen from "./LoadingScreen";
import "./App.css";
import ColorCode from "./ColorCode";
import ConditionalLiveChat from "./ConditionalLiveChat"; // Import the new component

export default function App() {
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const minDisplayTime = 1000;
    const startTime = Date.now();

    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setDataFetched(true);

      const elapsedTime = Date.now() - startTime;
      const remainingTime = minDisplayTime - elapsedTime;
      if (remainingTime > 0) {
        setTimeout(() => setLoading(false), remainingTime);
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    LogVisitor();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <ScrollToTop />
      <ColorCode />
      <div className="app">
        <ToastContainer />
        <Routes>
          <Route path="/live-chat" element={<MobileWindow />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/shop/all" element={<AllProducts />} />
          <Route path="/product/:product_title" element={<ProductDetails />} />
          <Route
            path="/shop/fresh-farm-produce"
            element={<ShopCategory category="Fresh Farm Produce" />}
          />
          <Route
            path="/shop/grains-and-staples"
            element={<ShopCategory category="Grains & Staples" />}
          />
          <Route
            path="/shop/poultry-and-meat"
            element={<ShopCategory category="Poultry & Meat" />}
          />
          <Route
            path="/shop/catfish"
            element={<ShopCategory category="Catfish" />}
          />
          <Route
            path="/shop/dairy-and-eggs"
            element={<ShopCategory category="Dairy & Eggs" />}
          />
          <Route
            path="/shop/beverages"
            element={<ShopCategory category="Beverages" />}
          />
          <Route
            path="/shop/snacks-and-confectionery"
            element={<ShopCategory category="Snacks & Confectionery" />}
          />
          <Route
            path="/shop/canned-and-packaged-foods"
            element={<ShopCategory category="Canned & Packaged Foods" />}
          />
          <Route
            path="/shop/frozen-foods"
            element={<ShopCategory category="Frozen Foods" />}
          />
          <Route
            path="/shop/condiments-and-sauces"
            element={<ShopCategory category="Condiments & Sauces" />}
          />
          <Route path="/shop" element={<Shop />} />
          <Route path="/order/:orderId" element={<OrderDetails />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <MobileFooter />
      </div>
      <ConditionalLiveChat />
      <BackToTop />
    </Router>
  );
}
