import React from "react";
import "./Sorting.css";

export default function Sorting({ onSortChange }) {
  return (
    <div className="sorting">
      <p>Sort By</p>
      <select
        className="sort-select"
        onChange={(e) => onSortChange(e.target.value)}
        aria-label="Sort products by"
      >
        <option value="newest">Newest</option>
        <option value="price-asc">Price: Low to High</option>
        <option value="price-desc">Price: High to Low</option>
        <option value="popularity">Popularity</option>
      </select>
    </div>
  );
}
