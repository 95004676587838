import React, { useRef, useEffect } from "react";
import "./RoundCategory.css";
import "../Boot/Boot.css";
import { category_list } from "../Assets/Assets";

export default function RoundCategory({ category, setCategory }) {
  const categoryRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (categoryRef.current) {
        const isSticky = window.scrollY > 100;
        categoryRef.current.classList.toggle("sticky", isSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleCategoryClick = (categoryName) => {
    setCategory((prev) => (prev === categoryName ? "All" : categoryName));

    const section = document.getElementById(
      categoryName.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-")
    );
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 180,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="rounded_category" id="rounded_category">
      <div className="rounded_category-list">
        {category_list.map((item, index) => {
          return (
            <div
              onClick={() => handleCategoryClick(item.category_name)}
              key={index}
              className="rounded_category-list-item"
            >
              <img
                className={category === item.category_name ? "active" : ""}
                src={item.category_image}
                alt="TrendBay Daily Solutions"
              />
              <p>{item.category_slug}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
