import React, { useState, useRef } from "react";
import "./Footer.css";
import "../Boot/Boot.css";

function Footer() {
  const emailInputRef = useRef(null);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://formspree.io/f/xbjvlpnq", {
        method: "POST",
        body: new FormData(event.target),
      });

      if (response.ok) {
        setSubscriptionMessage("Thank you for subscribing!");
        emailInputRef.current.value = "";
        alert("Thank you for subscribing!");
      } else {
        setSubscriptionMessage("Error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setSubscriptionMessage("Error occurred. Please try again.");
    }
  };

  return (
    <footer>
      <div className="footer_main d-lg-block d-xl-block d-xxl-block d-xs-none d-none d-md-block fixed-bottom">
        <div className="footer_main_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget company_widget wow fadeInLeft first_footer_column"
                  data-wow-delay="0.2s"
                >
                  <p>Be the first to get new updates from TrendBay!</p>
                  <form
                    onSubmit={(e) => handleFormSubmit(e)}
                    className="f_subscribe_two mailchimp"
                  >
                    <input
                      type="text"
                      id="EMAIL"
                      name="EMAIL"
                      className="form-control memail"
                      placeholder="Email"
                      ref={emailInputRef}
                    />
                    <button className="btn btn_get btn_get_two" type="submit">
                      Subscribe
                    </button>
                    <p className="mchimp-errmessage err error_footer_column"></p>
                    {subscriptionMessage && (
                      <p className="mchimp-sucmessage error_footer_column">
                        {subscriptionMessage}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft second_footer_column"
                  data-wow-delay="0.4s"
                >
                  <ul className="list-unstyled f_list pt-3">
                    <li>
                      <a href="/shop/fresh-farm-produce">Fresh Farm Produce</a>
                    </li>
                    <li>
                      <a href="/shop/grains-and-staples">Grains & Staples</a>
                    </li>
                    <li>
                      <a href="/shop/poultry-and-meat">Poultry & Meat</a>
                    </li>
                    <li>
                      <a href="/shop/catfish">Catfish</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft third_footer_column"
                  data-wow-delay="0.6s"
                >
                  <ul className="list-unstyled f_list pt-3">
                    <li>
                      <a href="/shop/dairy-and-eggs">Dairy & Eggs</a>
                    </li>
                    <li>
                      <a href="/shop/frozen-foods">Frozen Foods</a>
                    </li>
                    <li>
                      <a href="/shop/beverages">Beverages</a>
                    </li>
                    <li>
                      <a href="/shop/beverages">Juices</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft fourth_footer_column"
                  data-wow-delay="0.6s"
                >
                  <ul className="list-unstyled f_list pt-3">
                    <li>
                      <a href="/shop/snacks-and-confectionery">
                        Snacks & Confectionery
                      </a>
                    </li>
                    <li>
                      <a href="/shop/snacks-and-confectionery">Chips</a>
                    </li>
                    <li>
                      <a href="/shop/canned-and-packaged-foods">
                        Canned & Packaged Foods
                      </a>
                    </li>
                    <li>
                      <a href="/shop/condiments-and-sauces">
                        Condiments & Sauces
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bg">
          <div className="footer_bg_bus"></div>
          <div className="footer_bg_bike"></div>
        </div>
        <div className="bottom_footer">
          <div className="container">
            <div className="row text-center">
              <div className="col-sm-6 copyright">
                <p className="mt-1 mb-2 f_400">
                  ©{" "}
                  <strong>
                    <a
                      className="text-success"
                      href="https://trendbayds.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TrendBay Daily Solutions LTD
                    </a>{" "}
                  </strong>
                  2024 All rights reserved.
                </p>
              </div>
              <div className="col-sm-6 text-muted ciz">
                <p>
                  Designed by{" "}
                  <a
                    className="text-success"
                    href="https://cizonet.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cizonet Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
