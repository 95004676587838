import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useStateValue } from "../../StateProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import HomeIcon from "@mui/icons-material/Home";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PersonIcon from "@mui/icons-material/Person";
import "./MobileFooter.css";
import "../Boot/Boot.css";
import greenLogo from "../Assets/images/green-logo.png";
import MobileWindow from "./MobileWindow";

export default function MobileFooter() {
  const [{ cart, user }, dispatch] = useStateValue();
  const [activeTab, setActiveTab] = useState("home");
  const [showContactPopup, setShowContactPopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveTab("home");
    } else if (path.startsWith("/shop")) {
      setActiveTab("shop");
    } else if (path.startsWith("/cart")) {
      setActiveTab("cart");
    } else if (path.startsWith("/live-chat")) {
      setActiveTab("contact");
    } else if (
      path.startsWith("/signup") ||
      path.startsWith("/login") ||
      path.startsWith("/account")
    ) {
      setActiveTab("account");
    }
  }, [location]);

  const handleContactClick = (e) => {
    e.stopPropagation();
    setActiveTab("contact");
    setShowContactPopup(true);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".contact-popup")) {
      setShowContactPopup(false);
    }
  };

  useEffect(() => {
    if (showContactPopup) {
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showContactPopup]);

  const handleLinkClick = (tab) => {
    setActiveTab(tab);
    setShowContactPopup(false);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const submitButton = document.querySelector(".contact-popup-button");
    submitButton.classList.add("loading");
    submitButton.disabled = true;

    emailjs
      .sendForm("service_ppcp8bs", "template_w3ywg6x", form.current, {
        publicKey: "DHhTcwyiLDvMh3zm2",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert("TrendBay DS has received your message. Expect a swift reply!");
          window.location.href = "/";
        },
        (error) => {
          console.log("FAILED...", error);
          submitButton.classList.remove("loading");
          submitButton.disabled = false;
        }
      );
  };

  return (
    <>
      <div className={showContactPopup}>
        <div className="container"></div>
      </div>
      <div className="mobile-fixed-bottom navbar navbar-expand-xl navbar-light d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block fixed-bottom">
        <div className="icon-container">
          <Link
            to="/"
            className="icon-container"
            onClick={() => handleLinkClick("home")}
          >
            {activeTab === "home" ? (
              <HomeIcon sx={{ color: "black", fontSize: "32px" }} />
            ) : (
              <HomeIcon sx={{ color: "#B0B0B0", fontSize: "32px" }} />
            )}
            <span className={activeTab === "home" ? "active" : ""}>Home</span>
          </Link>
        </div>
        <div className="icon-container">
          <Link
            to="/shop"
            className="icon-container"
            onClick={() => handleLinkClick("shop")}
          >
            {activeTab === "shop" ? (
              <RemoveRedEyeIcon sx={{ color: "black", fontSize: "32px" }} />
            ) : (
              <RemoveRedEyeIcon sx={{ color: "#B0B0B0", fontSize: "32px" }} />
            )}
            <span className={activeTab === "shop" ? "active" : ""}>Shop</span>
          </Link>
        </div>
        <div className="icon-container cart-icon">
          <Link
            to="/cart"
            className="icon-container cart-icon"
            onClick={() => handleLinkClick("cart")}
          >
            {activeTab === "cart" ? (
              <ShoppingCartRoundedIcon
                sx={{
                  backgroundColor: "#028644",
                  color: "white",
                  borderRadius: "50%",
                  padding: "7px",
                  fontSize: "45px",
                }}
              />
            ) : (
              <ShoppingCartRoundedIcon
                sx={{
                  backgroundColor: "#01d26a",
                  color: "white",
                  borderRadius: "50%",
                  padding: "7px",
                  fontSize: "45px",
                }}
              />
            )}
            <span className={activeTab === "cart" ? "active" : ""}>Cart</span>
          </Link>
        </div>
        <div className="icon-container">
          <Link
            to="/live-chat"
            className="icon-container"
            onClick={() => handleLinkClick("contact")}
          >
            {activeTab === "contact" ? (
              <RemoveRedEyeIcon sx={{ color: "black", fontSize: "32px" }} />
            ) : (
              <RemoveRedEyeIcon sx={{ color: "#B0B0B0", fontSize: "32px" }} />
            )}
            <span className={activeTab === "contact" ? "active" : ""}>Contact</span>
          </Link>
        </div>
        <div className="icon-container">
          <div className="icon-container">
            <Link
              to={user ? "/account" : "/signup"}
              className="icon-container"
              onClick={() => handleLinkClick("account")}
            >
              {activeTab === "account" ? (
                <PersonIcon sx={{ color: "black", fontSize: "32px" }} />
              ) : (
                <PersonIcon sx={{ color: "#B0B0B0", fontSize: "32px" }} />
              )}
              <span className={activeTab === "account" ? "active" : ""}>
                Account
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
