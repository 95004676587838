import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Product from "./Product";
import { useTitle } from "../../hooks/useTitle";
import "./AllProducts.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import MobileSearch from "../../Components/Mobile/MobileSearch";
import SideBar from "./SideBar";
import "./SideBar.css";
import Sorting from "../Shop/Sorting";
import Loading from "../../Loading";

export default function AllProducts() {
  useTitle("All Products - TrendBay");

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sort, setSort] = useState("newest");
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map((doc) => ({
          product_id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let updatedProducts = [...products];

      // Apply category filter
      if (filters.category && filters.category !== "all") {
        updatedProducts = updatedProducts.filter(
          (product) => product.category_name === filters.category
        );
      }

      // Apply price range filter
      if (filters.minPrice !== undefined) {
        updatedProducts = updatedProducts.filter(
          (product) => product.product_price >= filters.minPrice
        );
      }
      if (filters.maxPrice !== undefined) {
        updatedProducts = updatedProducts.filter(
          (product) => product.product_price <= filters.maxPrice
        );
      }
      // Apply availability filter
      if (filters.availability) {
        updatedProducts = updatedProducts.filter(
          (product) => product.availability === filters.availability
        );
      }

      // Sorting
      switch (sort) {
        case "price-asc":
          updatedProducts.sort((a, b) => a.product_price - b.product_price);
          break;
        case "price-desc":
          updatedProducts.sort((a, b) => b.product_price - a.product_price);
          break;
        case "popularity":
          updatedProducts.sort((a, b) => b.popularity - a.popularity);
          break;
        case "newest":
        default:
          updatedProducts.sort((a, b) => b.product_id - a.product_id);
          break;
      }

      setFilteredProducts(updatedProducts);
    };

    applyFilters();
  }, [products, filters, sort]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleSortChange = (sortOption) => {
    setSort(sortOption);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <MobileSearch />
      <Navbar />
      <div className="below_navbar">
        <div className="topnav-centered">
          <a href="/">Home</a>
          <a href="/shop">Shop</a>
          <a href="/shop/all" className="active">
            All Products
          </a>
        </div>
      </div>
      <div className="container mb-5">
        <div className="main-container">
          <SideBar onFilterChange={handleFilterChange} />
          <div className="all-products container">
            <div className="float-right mt-3 mb-4">
              <Sorting onSortChange={handleSortChange} />
            </div>
            <div className="all_product-card container">
              {currentProducts.length > 0 ? (
                currentProducts.map((product) => (
                  <Product
                    key={product.product_id}
                    product_id={product.product_id}
                    product_image={product.product_image}
                    product_title={product.product_title}
                    product_price={product.product_price}
                    product_description={product.product_description}
                    category_name={product.category_name}
                  />
                ))
              ) : (
                <Loading />
              )}
            </div>
            <div className="pagination">
              {Array.from(
                {
                  length: Math.ceil(filteredProducts.length / productsPerPage),
                },
                (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={`page-button ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
