import React, { useState, useEffect } from "react";
import "./Product.css";
import "../../Components/Boot/Boot.css";
import { useStateValue } from "../../StateProvider";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

function slugify(text) {
  return text
    ? text
        .toString()
        .toLowerCase()
        .replace(/[^\w\s]/g, "") // Remove non-word characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/-+$/g, "") // Remove trailing hyphens
    : "";
}

function Product({
  product_id,
  product_image,
  product_title,
  product_price,
  product_description,
  category_name,
}) {
  const [{ cart }, dispatch] = useStateValue();
  const [isAdded, setIsAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [available, setAvailable] = useState(true);
  const navigate = useNavigate();

  const formatPrice = (price) =>
    parseFloat(price.toString().replace(/,/g, "")).toLocaleString("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const docRef = doc(db, "products", product_id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();
          setAvailable(productData.available);

          if (!productData.available) {
            const productInCart = cart.find((item) => item.id === product_id);
            if (productInCart) {
              dispatch({ type: "REMOVE_FROM_CART", id: product_id });
              setIsAdded(false);
              toast.warn(`${product_title} is Out of Stock.`, {
                autoClose: 2000,
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchAvailability();
  }, [product_id, cart, dispatch, product_title]);

  useEffect(() => {
    const productInCart = cart.find((item) => item.id === product_id);
    if (productInCart) {
      setIsAdded(true);
      setQuantity(productInCart.quantity);
    }
  }, [cart, product_id]);

  const addToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      item: {
        id: product_id,
        title: product_title,
        image: product_image,
        price: product_price,
      },
    });
    toast.success(`${product_title} added to your cart!`, { autoClose: 2000 });
    setIsAdded(true);
  };

  const updateQuantity = (newQuantity) => {
    if (newQuantity < 1) {
      dispatch({ type: "REMOVE_FROM_CART", id: product_id });
      setIsAdded(false);
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        id: product_id,
        quantity: newQuantity,
      });
      setQuantity(newQuantity);
    }
  };

  const handleViewCart = () => navigate("/cart");

  return (
    <div className="product__style">
      <Link to={`/product/${slugify(product_title)}`}>
        <div className="home__card">
          <img
            className="card-img-top mb-1"
            src={product_image}
            alt={product_title}
          />
          <div className="card-header card___header text-center">
            <p>{product_title}</p>
          </div>
          <div className="home_price card-footer text-center">
            <strong>₦{formatPrice(product_price)}</strong>
          </div>
          <button
            className="add-to-cart"
            onClick={isAdded ? handleViewCart : addToCart}
            disabled={!available}
          >
            {available
              ? isAdded
                ? `(${quantity}) in Cart`
                : "Add to Cart"
              : "Out of Stock"}
          </button>
        </div>
      </Link>
    </div>
  );
}

export default Product;
