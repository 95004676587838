import { useEffect } from "react";

const StatusBar = ({ color }) => {
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", color);
    }
  }, [color]);

  return null;
};

export default StatusBar;
