import React from "react";
import { useLocation } from "react-router-dom";
import LiveChat from "./Pages/LiveChat/LiveChat";

const ConditionalLiveChat = () => {
  const location = useLocation();

  const hideLiveChatPaths = [
    "/login",
    "/signup",
    "/verify-email",
    "/reset-password",
  ];

  const hideLiveChat = hideLiveChatPaths.includes(location.pathname);

  return !hideLiveChat ? <LiveChat /> : null;
};

export default ConditionalLiveChat;
