import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "../../Components/Mobile/MobileNavbar.css";
import "../../Components/Boot/Boot.css";
import SearchIcon from "@mui/icons-material/Search";

function slugify(text) {
  if (!text) return "";
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

export default function MobileSearch() {
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    setShowResults(value.length > 0);
  };

  const filteredItems = products.filter((product) =>
    product.product_title.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block">
      <div className="search-mobile container-fluid">
        <div className="mobile_header__search navbar-form">
          <input
            className="mobile_header__searchInput"
            type="text"
            placeholder="Search TrendBay: groceries, poultry, etc."
            value={query}
            onChange={handleInputChange}
          />
          <div className="mobile_header__searchIcon pr-2">
            <SearchIcon fontSize="medium" />
          </div>
          {showResults && (
            <div className="mobile_search-results">
              {filteredItems.length === 0 ? (
                <div className="mobile_search-result-item">
                  No results found
                </div>
              ) : (
                filteredItems.map((product) => (
                  <div key={product.id} className="mobile_search-result-item">
                    <Link
                      to={`/product/${slugify(product.product_title)}`}
                      state={{ product_id: product.id }}
                    >
                      <img
                        src={product.product_image}
                        alt={product.product_title}
                      />
                      <p>{product.product_title}</p>
                    </Link>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <div className="shop-tabs"></div>
    </div>
  );
}
