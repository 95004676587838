import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import MobileNavbar from "../../Components/Mobile/MobileNavbar";
import "./OrderDetails.css";
import LoadingScreen from "../../LoadingScreen";
import Invoice from "./Invoice";

const formatPrice = (price) => {
  if (isNaN(price)) return "Invalid Price";
  return parseFloat(price).toLocaleString("en-NG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

function OrderDetails() {
  const [order, setOrder] = useState(null);
  const { orderId } = useParams();
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const [isInvoiceVisible, setInvoiceVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      const fetchOrderDetails = async () => {
        const orderRef = doc(db, "orders", orderId);
        const orderSnap = await getDoc(orderRef);
        if (orderSnap.exists()) {
          setOrder(orderSnap.data());
        } else {
          console.log("No such document!");
        }
      };
      fetchOrderDetails();
    }
  }, [user, navigate, orderId]);

  useEffect(() => {
    if (isInvoiceVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isInvoiceVisible]);

  const generateInvoice = async () => {
    try {
      const orderRef = doc(db, "orders", orderId);
      const orderSnap = await getDoc(orderRef);

      if (orderSnap.exists()) {
        const orderData = orderSnap.data();

        if (orderData.invoice) {
          setInvoiceNumber(orderData.invoice);
          setInvoiceVisible(true);
        } else {
          const randomDigits = Math.floor(13 + Math.random() * 90);
          const newInvoiceNumber = `10${randomDigits}`;

          await updateDoc(orderRef, {
            invoice: newInvoiceNumber,
          });

          setInvoiceNumber(newInvoiceNumber);
          setInvoiceVisible(true);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  if (!order) return <LoadingScreen />;

  const discountRate = 0.075;
  const discountAmount = order.orderTotal * discountRate;
  const subtotalWithDiscount = order.orderTotal - discountAmount;

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString();
  };

  return (
    <div>
      <Navbar />
      <MobileNavbar />
      <div className="order-details container">
        <h4 className="text-center font-weight-bolder">Order Details</h4>
        <br />
        <div className="order-header d-flex justify-content-between align-items-center">
          <div>
            <h5>Order #{order.orderNumber || "N/A"}</h5>
            <p>Order date: {formatDate(order.createdAt)}</p>
            <p className="text-success">Estimated delivery: TBD</p>
          </div>
          <div onClick={generateInvoice}>
            <button className="btn btn-success">Invoice</button>
          </div>
        </div>
        <hr />
        <div className="order-items mb-4">
          {order.cart.map((item, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div className="d-flex align-items-center">
                <img
                  src={item.image}
                  alt={item.title}
                  className="product-image mr-3"
                />
                <div>
                  <h5 className="mb-0">{item.title}</h5>
                  <p className="mb-0">{item.description}</p>
                </div>
              </div>
              <div className="text-right">
                <p className="mb-0">₦{formatPrice(item.price)}</p>
                <p className="mb-0">Qty: {item.quantity}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h5>Need help?</h5>
            <p>
              <a href="/order-issues">Order Issues</a>
            </p>
            <p>
              <a href="/delivery-info">Delivery Info</a>
            </p>
            <p>
              <a href="/returns">Returns</a>
            </p>
          </div>
          <div className="col-md-6">
            <h5>Contact Information</h5>
            <p>{order.shippingInfo.email}</p>
            <p>{order.shippingInfo.phoneNumber}</p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h5>Shipping Address</h5>
            <p>{order.shippingInfo.address}</p>
            <p>
              {order.shippingInfo.city}, {order.shippingInfo.state},{" "}
              {order.shippingInfo.country}
            </p>
            <p>{order.shippingInfo.phoneNumber}</p>
          </div>
          <div className="col-md-6">
            <h5>Order Summary</h5>
            <p>Subtotal(w/D): ₦{formatPrice(subtotalWithDiscount)}</p>
            <p>Tax: 7.5%</p>
            <p>Total: ₦{formatPrice(order.orderTotal)}</p>
          </div>
        </div>
      </div>
      {isInvoiceVisible && (
        <div className="contact-overlay">
          <div
            className="contact-form-container"
            onClick={(e) => e.stopPropagation()}
          >
            <Invoice
              isVisible={isInvoiceVisible}
              onClose={() => setInvoiceVisible(false)}
              order={order}
              invoiceNumber={invoiceNumber}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default OrderDetails;
