import React from "react";
import "../../Components/Boot/Boot.css";
import "./NotFound.css";
import Navbar from "../../Components/Navbar/Navbar";
import MobileNavbar from "../../Components/Mobile/MobileNavbar";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Mobile/MobileFooter";

function NotFound() {
  return (
    <div>
      <MobileNavbar />
      <Navbar />
      <div className="d-flex align-items-center justify-content-center not_found">
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">😖Oops!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <a href="/" className="btn btn-success">
            Go Home
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;
