import React, { useEffect, useState } from "react";
import "./Home.css";
import "../../Components/Boot/Boot.css";
import Product from "../Product/Product";
import Navbar from "../../Components/Navbar/Navbar";
import MobileNavbar from "../../Components/Mobile/MobileNavbar";
import RoundCategory from "../../Components/RoundCategory/RoundCategory";
import Footer from "../../Components/Footer/Footer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HeaderImage from "../../Components/Assets/images/header_main.png";
import HomeImage1 from "../../Components/Assets/images/header_home1.png";
import HomeImage from "../../Components/Assets/images/mobile_home1.png";
import BannerImage1 from "../../Components/Assets/images/banner_2.png";
import BannerImage2 from "../../Components/Assets/images/banner_3.png";
import BannerImage3 from "../../Components/Assets/images/poultry1.png";
import BannerImage4 from "../../Components/Assets/images/poultry2.png";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const shuffleArray = (array) => {
  let shuffled = array.slice();
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export default function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hideLeftButton, setHideLeftButton] = useState(true);
  const [hideRightButton, setHideRightButton] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("ViewAll");
  const [shuffledProducts, setShuffledProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const q = query(
          collection(db, "products"),
          where("featured", "==", true)
        );
        const querySnapshot = await getDocs(q);
        const productsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const shuffled = shuffleArray(products);
    setShuffledProducts(shuffled);
  }, [products]);

  const handleScroll = (event) => {
    const scrollingRow = event.target;
    const maxScrollLeft = scrollingRow.scrollWidth - scrollingRow.clientWidth;

    if (scrollingRow.scrollLeft === 0) {
      setHideLeftButton(true);
      setHideRightButton(false);
    } else if (scrollingRow.scrollLeft === maxScrollLeft) {
      setHideLeftButton(false);
      setHideRightButton(true);
    } else {
      setHideLeftButton(false);
      setHideRightButton(false);
    }
  };

  const scrollLeft = () => {
    const scrollingRow = document.querySelector(".scrolling-row");
    const cellWidth = scrollingRow.offsetWidth / 6;
    const newPosition = scrollPosition - cellWidth;
    setScrollPosition(newPosition > 0 ? newPosition : 0);
    scrollingRow.scrollTo({ left: newPosition, behavior: "smooth" });
  };

  const scrollRight = () => {
    const scrollingRow = document.querySelector(".scrolling-row");
    const cellWidth = scrollingRow.offsetWidth / 6;
    const newPosition = scrollPosition + cellWidth;
    setScrollPosition(newPosition);
    scrollingRow.scrollTo({ left: newPosition, behavior: "smooth" });
  };

  const renderProductsByCategory = (categoryName) => {
    return shuffledProducts
      .filter((product) => product.category_name === categoryName)
      .slice(0, 6)
      .map((product) => (
        <Product
          key={product.product_id}
          product_id={product.product_id}
          product_image={product.product_image}
          product_title={product.product_title}
          product_price={product.product_price}
          product_description={product.product_description}
          category_name={product.category_name}
        />
      ));
  };

  return (
    <div>
      <MobileNavbar />
      <Navbar />
      <div className="home_Container container">
        <div className="header_image mt-4 d-none d-md-block">
          <a href="/shop/all">
            <img
              src={HeaderImage}
              className="header_image-img"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <section className="roundedcat-home">
          <RoundCategory category={category} setCategory={setCategory} />
        </section>
        <section className="ffp-home" id="fresh-farm-produce">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Fresh Farm Produce
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/fresh-farm-produce">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("fresh-farm-produce")}
            </div>
          </div>
        </section>
        <section className="sc_container-row mb-2">
          <div className="scrolling-row" onScroll={handleScroll}>
            <div className="category-cell scroll1 mr-2">
              <a href="/shop/poultry-and-meat">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll2 mr-2">
              <a href="/shop/fresh-farm-produce">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll3 mr-2">
              <a href="/shop/dairy-and-eggs">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll4 mr-2">
              <a href="/shop/grains-and-staples">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll5 mr-2">
              <a href="/shop/poultry-and-meat">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll6 mr-2">
              <a href="/shop/fresh-farm-produce">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll7 mr-2">
              <a href="/shop/canned-and-packaged-foods">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll8 mr-2">
              <a href="/shop/beverages">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll9 mr-2">
              <a href="/shop/dairy-and-eggs">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
            <div className="category-cell scroll10 mr-3">
              <a href="/shop/grains-and-staples">
                <div className="scroll_hover-overlay">
                  <button className="scroll_shop-now-button">Shop Now</button>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className="pb-2" id="poultry-and-meat">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Poultry & Meat
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/poultry-and-meat">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("poultry-and-meat")}
            </div>
          </div>
        </section>
        <section className="mt-4 pb-2" id="grains-and-staples">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Grains & Staples
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/grains-and-staples">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("grains-and-staples")}
            </div>
          </div>
        </section>
        <div className="header_image d-none d-md-block mt-4">
          <a href="/shop/fresh-farm-produce">
            <img
              src={HomeImage1}
              className="header_image-img"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <div className="home_image2 d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block mt-4">
          <a href="/shop/fresh-farm-produce">
            <img
              src={HomeImage}
              className="header_image-img2"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <section className="mt-4 pt-3 pb-2" id="frozen-foods">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Frozen Foods
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/frozen-foods">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("frozen-foods")}
            </div>
          </div>
        </section>
        <section className="mt-4 pt-3 pb-2" id="canned-and-packaged-foods">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Canned & Packaged Foods
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/canned-and-packaged-foods">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("canned-and-packaged-foods")}
            </div>
          </div>
        </section>
        <div className="header_image d-none d-md-block mt-4">
          <a href="/shop/poultry-and-meat">
            <img
              src={BannerImage3}
              className="header_image-img"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <div className="home_image2 d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block mt-4 pb-2">
          <a href="/shop/poultry-and-meat">
            <img
              src={BannerImage4}
              className="header_image-img2"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <section className="mt-4 pb-2" id="dairy-and-eggs">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Dairy & Eggs
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/dairy-and-eggs">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("dairy-and-eggs")}
            </div>
          </div>
        </section>
        <section className="mt-4 pb-2" id="beverages">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Beverages
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/beverages">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("beverages")}
            </div>
          </div>
        </section>
        <div className="header_image d-none d-md-block mt-4">
          <a href="/shop/all">
            <img
              src={BannerImage1}
              className="header_image-img"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <div className="home_image2 d-lg-none d-xl-none d-xxl-none d-md-none d-xs-block mt-4 pb-2">
          <a href="/shop/all">
            <img
              src={BannerImage2}
              className="header_image-img2"
              alt="TrendBay Daily Solutions"
            />
          </a>
        </div>
        <section className="mt-4 pb-2" id="snacks-and-confectionery">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Snacks & Confectionery
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/snacks-and-confectionery">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("snacks-and-confectionery")}
            </div>
          </div>
        </section>
        <section className="mt-4 mb5" id="condiments-and-sauces">
          <div>
            <h5
              data-toggle="tooltip"
              data-placement="top-end"
              title="Explanation text"
            >
              Condiments & Sauces
            </h5>
            <span className="float-right text-success main_view-all">
              <a href="/shop/condiments-and-sauces">
                View All
                <ArrowRightAltIcon />
              </a>
            </span>
            <div className="pt-2 home_product-card">
              {renderProductsByCategory("condiments-and-sauces")}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
