import React from "react";
import { useStateValue } from "../../StateProvider";
import CheckoutProduct from "./CheckoutProduct";

function ReviewCart() {
  const [{ cart }] = useStateValue();
  return (
    <div className="checkout__items">
      {cart.map((item) => (
        <CheckoutProduct
          key={item.id}
          product_id={item.id}
          product_image={item.image}
          product_title={item.title}
          product_price={item.price}
        />
      ))}
    </div>
  );
}

export default ReviewCart;
