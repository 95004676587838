import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { useTitle } from "../../hooks/useTitle";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import NoOrder from "./NoOrder";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./OrderList.css";
import MobileNavbar from "../../Components/Mobile/MobileNavbar";
import Loading from "../../Loading";

const formatPrice = (price) => {
  if (isNaN(price)) return "Invalid Price";
  return parseFloat(price).toLocaleString("en-NG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

function OrderList() {
  useTitle("Your Orders - TrendBay DS");
  const [orders, setOrders] = useState([]);
  const [loadingTimeoutExpired, setLoadingTimeoutExpired] = useState(false);
  const [{ user }] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      const fetchOrders = async () => {
        const q = query(
          collection(db, "orders"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const ordersList = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          orderId: doc.id,
        }));
        setOrders(ordersList);
      };
      fetchOrders();
    }
  }, [user, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTimeoutExpired(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Navbar />
      <MobileNavbar />
      <div className="order-list container">
        <h6>Your Orders</h6>
        {orders.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TableRow key={order.orderId}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Link to={`/order/${order.orderId}`}>
                        #{order.orderNumber}
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Link to={`/order/${order.orderId}`}>
                        ₦{formatPrice(order.orderTotal)}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/order/${order.orderId}`}>
                        {new Date(
                          order.createdAt.seconds * 1000
                        ).toLocaleDateString()}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : loadingTimeoutExpired ? (
          <NoOrder />
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default OrderList;
