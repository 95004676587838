import React from "react";
import { useLocation } from "react-router-dom";
import StatusBar from "./StatusBar";

const ColorCode = () => {
  const location = useLocation();

  let color;
  if (location.pathname.startsWith("/product/")) {
    color = "#ffffff";
  } else {
    switch (location.pathname) {
      case "/account":
      case "/reset-password":
      case "/thank-you":
      case "/login":
      case "/signup":
      case "/checkout":
      case "/verify-email":
        color = "#ffffff";
        break;
      default:
        color = "#01d26a";
    }
  }

  return <StatusBar color={color} />;
};

export default ColorCode;
