import React, { useState, useEffect } from "react";
import "./AccountPage.css";
import { useStateValue } from "../../StateProvider";
import "../../Components/Boot/Boot.css";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useTitle } from "../../hooks/useTitle";

const Account = () => {
  useTitle("My Account - TrendBay DS");
  const [{ cart, user }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const totalUniqueItems = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleSignOut = () => {
    if (user) {
      auth.signOut().then(() => {
        window.location.href = "/";
      });
    }
  };

  const firstName = user
    ? user.displayName
      ? user.displayName.split(" ")[0]
      : null
    : null;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch({ type: "SET_USER", user: authUser });
      } else {
        dispatch({ type: "SET_USER", user: null });
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, navigate]);

  return (
    <div className="account-page container">
      <div className="account-header navbar">
        <h2>Account</h2>
        <Link to="/cart">
          <div className="account_cart">
            <ShoppingCartCheckoutIcon sx={{ fontSize: "27px" }} />
            <span className="account_cartCount">{totalUniqueItems}</span>
          </div>
        </Link>
      </div>
      <h3 className="navbar acc-header">
        <strong>Hi, {firstName}</strong>
      </h3>
      <ul className="account-options ml-3">
        <li>
          <Link to="/orders">
            <LocalShippingOutlinedIcon className="mr-4" />
            My Orders
          </Link>
        </li>
        <hr></hr>
        <li onClick={handleSignOut} className="signout-account">
          <ExitToAppRoundedIcon className="mr-4" /> Sign out
        </li>
      </ul>
    </div>
  );
};

export default Account;
