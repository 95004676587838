import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";

const LogVisitor = async () => {
  try {
    await addDoc(collection(db, "visitors"), {
      timestamp: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export default LogVisitor;
