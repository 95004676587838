import React, { useEffect, useState } from "react";
import "./VerifyEmail.css";
import "../../Components/Boot/Boot.css";
import LoginLogo from "../../Components/Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import "../../Components/Mobile/MobileFooter";
import { useTitle } from "../../hooks/useTitle";

function VerifyEmail() {
  useTitle("Verify your Email for TrendBay DS");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setEmail(user.email);
    } else {
      navigate("/signup");
    }
  }, [navigate]);

  return (
    <div className="ve">
      <a href="/">
        <img className="ve__logo" src={LoginLogo} alt="TrendBay" />
      </a>

      <div className="ve_container">
        <h2 className="text-center">Verify Your Email Address</h2>
        <p>
          We sent a link to your email address: <strong>{email}</strong>
        </p>
        <p>Follow the link in your email to verify</p>
        <a href="/">
          <button className="ve_signInButton mb-3">Skip for Now</button>
        </a>
        <p className="lastlast">Please check Inbox/Spam/Junk Folder</p>
      </div>
    </div>
  );
}

export default VerifyEmail;
