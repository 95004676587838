import React, { useState } from "react";
import "./Login.css";
import "../../Components/Boot/Boot.css";
import LoginLogo from "../../Components/Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "../../Components/Mobile/MobileFooter";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTitle } from "../../hooks/useTitle";

function Login() {
  useTitle("Login to TrendBay");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (userCredential.user.emailVerified) {
          navigate("/");
        } else {
          navigate("/verify-email");
        }
      })
      .catch((error) => {
        setError("Email or Password incorrect");
        setLoading(false);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  return (
    <div className="login">
      <a href="/">
        <img className="login__logo" src={LoginLogo} alt="TrendBay" />
      </a>

      <div className="login_container">
        <h2 className="text-center">Access Your TrendBay Account</h2>
        <form className="form_login">
          <h5>Email</h5>
          <input type="text" value={email} onChange={handleEmailChange} />

          <h5>Password</h5>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          {error && <div className="error">{error}</div>}

          <p className="forgot-password float-right">
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
          <button
            type="submit"
            onClick={handleLogin}
            className="Login_signInButton"
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign In"}
          </button>
        </form>
        <p>
          By logging in, you agree to the{" "}
          <a href="/">
            <strong>Privacy Policy</strong>
          </a>{" "}
          and{" "}
          <Link to="/">
            <strong>Terms of Service</strong>
          </Link>
        </p>

        <Link to="/signup">
          <button className="Login_signUpButton">
            Create your TrendBay Account
          </button>
        </Link>
      </div>
      <a href="/">
        <p className="go_home d-lg-block d-xl-block d-xxl-block d-md-block d-xs-none d-none">
          Home
        </p>
      </a>
    </div>
  );
}

export default Login;
