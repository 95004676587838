import React, { useState, useEffect } from "react";
import "./ChatWindow.css";
import EmailForm from "./EmailForm";
import ChatEngine from "./ChatEngine";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from 'axios';

function ChatWindow({ visible }) {
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    function connectToChatEngine(email) {
      axios.put(
        'https://api.chatengine.io/users/',
        { 
          username: email, 
          secret: email, 
          email: email 
        },
        { headers: { 'Private-Key': process.env.REACT_APP_CE_PRIVATE_KEY } }
      ).then((response) => {
        const chatUser = response.data;
        getOrCreateChat(chatUser);
      }).catch((error) => {
        console.error('Error connecting to Chat Engine', error);
      });
    }

    function getOrCreateChat(chatUser) {
      axios.put(
        'https://api.chatengine.io/chats/',
        { usernames: ['Support Chat', chatUser.email], is_direct_chat: true },
        { headers: { 'Private-Key': process.env.REACT_APP_CE_PRIVATE_KEY } }
      ).then((response) => {
        const newChat = response.data;
        setChat(newChat);
      }).catch((error) => {
        console.error('Error creating or getting chat', error);
      });
    }

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        connectToChatEngine(firebaseUser.email);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className={`chat-window ${visible ? "visible" : ""}`}>
      {!user && (
        <EmailForm visible={!user} />
      )}

      {user && chat && (
        <ChatEngine
          visible={user !== null && chat !== null}
          chat={chat}
          user={user}
        />
      )}
    </div>
  );
}

export default ChatWindow;
