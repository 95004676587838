import React, { useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import "../../Components/Boot/Boot.css";
import "./Invoice.css";
import logo from "../../Components/Assets/images/tb_logo.png";

const Invoice = ({ isVisible, onClose, order, invoiceNumber }) => {
  useEffect(() => {
    const originalColor = document
      .querySelector("meta[name='theme-color']")
      .getAttribute("content");
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#ffffff");
    }

    return () => {
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", originalColor);
      }
    };
  }, []);
  if (!isVisible) return null;

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString();
  };

  const formatPrice = (price) => {
    if (isNaN(price)) return "Invalid Price";
    return parseFloat(price).toLocaleString("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const discountRate = 0.075;
  const discountAmount = order.orderTotal * discountRate;
  const subtotalWithDiscount = order.orderTotal - discountAmount;

  return (
    <div className="invoice-overlay" onClick={onClose}>
      <div
        className="invoice-container contactus-popup"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <div className="mainIn">
          <div className="invoice-header">
            <div className="float-left text-sm-left header1">
              <div className="mr-2">
                <img src={logo} alt="TrendBay DS" />
              </div>
              <div className="comp">
                <h2 className="head1">TrendBay Daily Solutions LTD</h2>
                <p className="headp">hello@trendbayds.com</p>
              </div>
            </div>
            <div className="float-right text-sm-right mb-3 header2">
              <h1 className="head2">Invoice #{invoiceNumber || "N/A"}</h1>
              <p className="headp">Issued: {formatDate(order.createdAt)}</p>
            </div>
          </div>
        </div>
        <div className="invoice-body">
          <table>
            <thead>
              <tr>
                <th>Product(s)</th>
                <th>Quantity</th>
                <th>Sub-Total</th>
              </tr>
            </thead>
            <tbody>
              {order.cart.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.quantity}</td>
                  <td>₦{formatPrice(item.price * item.quantity)}</td>
                </tr>
              ))}
              <tr>
                <td className="float-right">Tax</td>
                <td>7.5%</td>
                <td>₦{formatPrice(discountAmount)}</td>
              </tr>
              <tr className="fw-bold">
                <td colSpan="2" className="text-right">
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>₦{formatPrice(order.orderTotal)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="invoice-footer">
          <div class="row">
            <div class="col-8">
              <p>
                <strong>Thank you for shopping with TrendBay DS</strong>
              </p>
            </div>
            <div class="col-4 float-right">
              <Button
                variant="contained"
                startIcon={<CheckCircleOutlineIcon />}
                sx={{
                  backgroundColor: "#028644",
                  "&:hover": {
                    backgroundColor: "#028644",
                  },
                }}
                onClick={() => {}}
                className="buttt"
              >
                PAID
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
