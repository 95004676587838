import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAXYNa0EnPxbWsvH2TmXTB9AHCiByRW2Xw",
  authDomain: "trend-bay.firebaseapp.com",
  projectId: "trend-bay",
  storageBucket: "trend-bay.appspot.com",
  messagingSenderId: "997154996681",
  appId: "1:997154996681:web:3687f8b48e4db06528e818",
  measurementId: "G-RPS6W2R3L2",
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, auth, storage };
