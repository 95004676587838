import React, { useState } from "react";
import "../../Components/Boot/Boot.css";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase"; // Import Firestore
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import LoginLogo from "../../Components/Assets/images/logo.png";
import { useTitle } from "../../hooks/useTitle";

function Signup() {
  useTitle("Sign up for TrendBay");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!firstName || !lastName || !email || !password) {
      setError("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must have at least 8 characters, 1 uppercase letter, 1 number & 1 symbol."
      );
      setLoading(false);
      return;
    }

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(user, {
        displayName: firstName,
      });

      await sendEmailVerification(user);

      await setDoc(doc(db, "customers", user.uid), {
        firstName,
        lastName,
        email,
        createdAt: new Date(),
      });

      navigate("/verify-email");
    } catch (error) {
      setError("Error in signing up. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setError("");
    setPasswordError("");
  };

  return (
    <div className="signup">
      <a href="/">
        <img className="signup__logo" src={LoginLogo} alt="TrendBay" />
      </a>
      <div className="signup_container">
        <h2 className="text-center">Create Your TrendBay Account</h2>
        {error && <div className="error">{error}</div>}
        {passwordError && <div className="error">{passwordError}</div>}
        <form className="form_auth">
          <div>
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={handleInputChange(setFirstName)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={handleInputChange(setLastName)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={handleInputChange(setEmail)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handleInputChange(setPassword)}
            />
          </div>
          <button
            className="signup_createButton"
            type="submit"
            onClick={handleSignUp}
            disabled={loading}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </form>
      </div>
      <p className="signup_P">
        By signing up, you agree you've read and accepted our{" "}
        <Link to="/">
          <strong>Terms and Conditions</strong>
        </Link>
        . Please see our
        <Link to="/">
          <strong> Privacy Policy</strong>
        </Link>{" "}
        for information on how we process your data.
      </p>
      <span className="signup_loginHere">
        Already have an account?{" "}
        <Link className="signup_loginHere_link" to="/login">
          <button className="signup_loginHere_Button ml-2">Login here</button>
        </Link>
      </span>
    </div>
  );
}

export default Signup;
