import React from "react";
import "./MobileCart.css";
import Subtotal from "./Subtotal";
import CartProduct from "./CartProduct";
import EmptyCart from "./EmptyCart";
import { useStateValue } from "../../StateProvider";
import { Link } from "react-router-dom";

const MobileCart = () => {
  const [{ cart, user }, dispatch] = useStateValue();

  return (
    <>
      {cart.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className="container mc__cart">
          {cart.map((item) => (
            <CartProduct
              key={item.id}
              product_id={item.id}
              product_image={item.image}
              product_title={item.title}
              product_price={item.price}
            />
          ))}
          <Subtotal />
        </div>
      )}
    </>
  );
};

export default MobileCart;
