import React, { useState, useEffect } from "react";
import "./CartProduct.css";
import { useStateValue } from "../../StateProvider";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CartProduct({
  product_id,
  product_title,
  product_image,
  product_price,
}) {
  const [{ cart }, dispatch] = useStateValue();

  const cartItem = cart.find((item) => item.id === product_id);
  const initialQuantity = cartItem ? cartItem.quantity : 1;

  const [quantity, setQuantity] = useState(initialQuantity);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const alt_title = product_title;

  const updateQuantity = (newQuantity) => {
    if (newQuantity < 1) {
      dispatch({
        type: "REMOVE_FROM_CART",
        id: product_id,
      });
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        id: product_id,
        quantity: newQuantity,
      });
      setQuantity(newQuantity);
    }
  };

  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  }).format(product_price * quantity);

  const removeFromCart = () => {
    dispatch({
      type: "REMOVE_FROM_CART",
      id: product_id,
    });
    
  };

   const notifyRemove = () => {
     toast(`${product_title} removed from your cart!`, {autoClose: 2000});
   };

   const handleNotifyRemove= () => {
     removeFromCart();
     notifyRemove();
   };

  return (
    <div className="cartProduct">
      <div className="row border-bottom">
        <div className="row cart__main align-items-center">
          <div className="col-2 cart_product_col-2">
            <img
              className="img-fluid cart_product_img"
              src={product_image}
              alt={alt_title}
            />
          </div>
          <div className="text-left cart_product_col">
            <div className="cartProduct_title mb-2 mt-2">{product_title}</div>
            <strong>{formattedPrice}</strong>
            <div className="mt-2 cart_product_quantity">
              <div className="cart_quantity-selector">
                <button onClick={() => updateQuantity(quantity - 1)}>-</button>
                <span>{quantity}</span>
                <button onClick={() => updateQuantity(quantity + 1)}>+</button>
              </div>
            </div>
          </div>

          <div className="col cart_product_col cartProduct_price">
            <span className="close" onClick={handleNotifyRemove}>
              <HighlightOffIcon
                fontSize="medium"
                style={{ color: "#ff0e0e" }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartProduct;
