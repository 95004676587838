import React from "react";
import "./DesktopCart.css";
import "./Cart.css";
import Subtotal from "./Subtotal";
import CartProduct from "./CartProduct";
import EmptyCart from "./EmptyCart";
import { useStateValue } from "../../StateProvider";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";

export default function DesktopCart() {
  const [{ cart, user }, dispatch] = useStateValue();

  const getFirstName = () => {
    if (user && user.displayName) {
      const names = user.displayName.split(" ");
      return names[0];
    }
    return "";
  };

  return (
    <>
      <Navbar />
      {cart.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className="container dc__cart">
          <div className="row cart_row">
            <div className="col-md-8 shopping_cart">
              <div className="cart_title">
                <div className="row cart_row">
                  <div className="col your_cart">
                    {user ? <h6>Hello, {getFirstName()}</h6> : null}
                    <h4>
                      <b>Your Cart</b>
                    </h4>
                  </div>
                </div>
              </div>
              {cart.map((item) => (
                <CartProduct
                  key={item.id}
                  product_id={item.id}
                  product_image={item.image}
                  product_title={item.title}
                  product_price={item.price}
                />
              ))}
              <div className="back-to-shop">
                <a href="/shop/all">
                  <KeyboardBackspaceIcon />
                  <span className="text-muted ml-1">Continue Shopping</span>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <Subtotal />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
