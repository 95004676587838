import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { getCartTotal } from "../../reducer";
import "./Checkout.css";
import ThanksTotal from "./ThanksTotal";
import "./ThankYou.css";
import { Link } from "react-router-dom";
import checkLogo from "../../Components/Assets/images/tb_logo.png";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTitle } from "../../hooks/useTitle";

function ThankYou(orderData) {
  useTitle("Thank You - TrendBay DS");
  const [{ cart, user, shippingInfo, billingInfo }, dispatch] = useStateValue();
  const firstName = user
    ? user.displayName
      ? user.displayName.split(" ")[0]
      : null
    : null;
  const navigate = useNavigate();
  const [orderTotal, setOrderTotal] = useState(0);
  const [showCart, setShowCart] = useState(false);
  const [orderReference, setOrderReference] = useState("");

  const handleToggleCart = () => {
    setShowCart(!showCart);
  };

  useEffect(() => {
    if (!shippingInfo) {
      navigate("/");
    }
  }, [shippingInfo, navigate]);

  useEffect(() => {
    const calculateOrderTotal = () => {
      const cartTotal = getCartTotal(cart);
      const shippingCost = 5000;
      const estimatedTax = cartTotal * 0.075;
      return (cartTotal + shippingCost + estimatedTax).toFixed(2);
    };

    const newOrderTotal = calculateOrderTotal();
    setOrderTotal(newOrderTotal);
  }, [cart]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const reference = params.get("reference");
    if (reference) {
      setOrderReference(reference);
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_CART" });
    };
  }, [dispatch]);

  return (
    <div className="checkout_payment thankYou mb-3">
      <div className="row">
        <div className="col-md-8 check_cart">
          <div className="cart_title checkout_title_section">
            <img
              src={checkLogo}
              className="check_logo mt-3 mb-4"
              alt="TrendBay-logo"
            />
            <h4 className="chch pt-4">
              <strong>Thank you, {firstName}!</strong>
            </h4>
            <div className="checkout__title thankYou__header">
              <CheckCircleOutlineOutlinedIcon className="thankYou__icon" />
              <p>Confirmation #{orderReference}</p>
            </div>
            <div className="header_thankyou">
              <h5>Your order is confirmed</h5>
              <p>You'll receive a confirmation email shortly.</p>
            </div>
            <div className="thankYou__details thankYou__container">
              <h4>Order Details</h4>
              <span className="float-right text-success main_view-all">
                <Link to="/orders">View Orders</Link>
              </span>

              {shippingInfo && (
                <>
                  <div className="accInfo">
                    <p>{shippingInfo.email}</p>
                    <p>{shippingInfo.phoneNumber}</p>
                  </div>
                  <div className="thankYou__section">
                    <h6 className="mt-3">Shipping Information</h6>
                    <p>{shippingInfo.fullName}</p>
                    <p>{shippingInfo.address}</p>
                    <p>
                      {shippingInfo.city}, {shippingInfo.state}
                    </p>
                    <p>{shippingInfo.country}</p>
                  </div>
                </>
              )}
              {billingInfo && (
                <>
                  <div className="thankYou__section">
                    <h6>Billing Information</h6>
                    <p>{billingInfo.fullName}</p>
                    <p>{billingInfo.address}</p>
                    <p>
                      {billingInfo.city}, {billingInfo.state}
                    </p>
                    <p>{billingInfo.country}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
