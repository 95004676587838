import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { getCartTotal } from "../../reducer";
import "./Checkout.css";
import { nigerianStates } from "../../Components/Assets/Assets";
import CheckoutTotal from "./CheckoutTotal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import checkLogo from "../../Components/Assets/images/tb_logo.png";
import { useTitle } from "../../hooks/useTitle";
import { db } from "../../firebase";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth } from "../../firebase";

function Checkout() {
  useTitle("Checkout - TrendBay DS");
  const [{ cart, user }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [orderTotal, setOrderTotal] = useState(0);
  const [shippingSameAsBilling, setShippingSameAsBilling] = useState(false);
  const [showCart, setShowCart] = useState(false);

  const [shippingFullName, setShippingFullName] = useState("");
  const [shippingEmail, setShippingEmail] = useState(user?.email || "");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingPhoneNumber, setShippingPhoneNumber] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCountry, setShippingCountry] = useState("Nigeria");

  const [billingFullName, setBillingFullName] = useState("");
  const [billingEmail, setBillingEmail] = useState(user?.email || "");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPhoneNumber, setBillingPhoneNumber] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("Nigeria");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleShippingSameAsBilling = () => {
    setShippingSameAsBilling(!shippingSameAsBilling);
    if (!shippingSameAsBilling) {
      setBillingFullName(shippingFullName);
      setBillingEmail(shippingEmail);
      setBillingAddress(shippingAddress);
      setBillingPhoneNumber(shippingPhoneNumber);
      setBillingCity(shippingCity);
      setBillingState(shippingState);
      setBillingCountry(shippingCountry);
    } else {
      setBillingFullName("");
      setBillingEmail("");
      setBillingAddress("");
      setBillingPhoneNumber("");
      setBillingCity("");
      setBillingState("");
      setBillingCountry("");
    }
  };

  const handleToggleCart = () => {
    setShowCart(!showCart);
  };

  const generateUniqueOrderNumber = async () => {
    const min = 100000;
    const max = 999999;
    let orderNumber;
    let isUnique = false;

    while (!isUnique) {
      orderNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      // Check if the orderNumber already exists
      const q = query(
        collection(db, "orders"),
        where("orderNumber", "==", orderNumber)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        isUnique = true;
      }
    }
    return orderNumber;
  };

  const handlePaymentSuccess = async (reference) => {
    dispatch({
      type: "SET_SHIPPING_INFO",
      shippingInfo: {
        fullName: shippingFullName,
        email: shippingEmail,
        address: shippingAddress,
        phoneNumber: shippingPhoneNumber,
        city: shippingCity,
        state: shippingState,
        country: shippingCountry,
      },
    });

    dispatch({
      type: "SET_BILLING_INFO",
      billingInfo: shippingSameAsBilling
        ? {
            fullName: shippingFullName,
            email: shippingEmail,
            address: shippingAddress,
            phoneNumber: shippingPhoneNumber,
            city: shippingCity,
            state: shippingState,
            country: shippingCountry,
          }
        : {
            fullName: billingFullName,
            email: billingEmail,
            address: billingAddress,
            phoneNumber: billingPhoneNumber,
            city: billingCity,
            state: billingState,
            country: billingCountry,
          },
    });

    const orderNumber = await generateUniqueOrderNumber();

    const orderData = {
      userId: user.uid,
      cart,
      orderTotal,
      shippingInfo: {
        fullName: shippingFullName,
        email: shippingEmail,
        address: shippingAddress,
        phoneNumber: shippingPhoneNumber,
        city: shippingCity,
        state: shippingState,
        country: shippingCountry,
      },
      billingInfo: shippingSameAsBilling
        ? {
            fullName: shippingFullName,
            email: shippingEmail,
            address: shippingAddress,
            phoneNumber: shippingPhoneNumber,
            city: shippingCity,
            state: shippingState,
            country: shippingCountry,
          }
        : {
            fullName: billingFullName,
            email: billingEmail,
            address: billingAddress,
            phoneNumber: billingPhoneNumber,
            city: billingCity,
            state: billingState,
            country: billingCountry,
          },
      paymentReference: reference,
      createdAt: new Date(),
      orderNumber: orderNumber,
    };

    try {
      const docRef = await addDoc(collection(db, "orders"), orderData);

      const orderId = docRef.id;

      await setDoc(
        doc(db, "orders", orderId),
        { orderId: orderId },
        { merge: true }
      );

      dispatch({
        type: "EMPTY_CART",
      });

      navigate(`/thank-you?reference=${reference}`);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("There was an issue creating your order. Please try again.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert("Please fill in all required fields.");
      return;
    }
  };

  useEffect(() => {
    const calculateOrderTotal = () => {
      const cartTotal = getCartTotal(cart);
      const shippingCost = 5000;
      const estimatedTax = cartTotal * 0.075;
      return (cartTotal + shippingCost + estimatedTax).toFixed(2);
    };

    const newOrderTotal = calculateOrderTotal();
    setOrderTotal(newOrderTotal);
  }, [cart]);

  const isFormValid = () => {
    if (
      !shippingFullName ||
      !shippingAddress ||
      !shippingPhoneNumber ||
      !shippingCity ||
      !shippingState
    ) {
      return false;
    }
    if (!shippingSameAsBilling) {
      if (
        !billingFullName ||
        !billingAddress ||
        !billingPhoneNumber ||
        !billingCity ||
        !billingState
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="checkout_payment">
      <form onSubmit={handleSubmit}>
        <div className="row cart_row">
          <div className="col-md-8 check_cart mb-3">
            <div className="cart_title checkout_title_section">
              <div className="back-to-shop d-none d-md-block">
                <Link to="/cart">
                  <KeyboardBackspaceIcon />
                </Link>
              </div>
              <img
                src={checkLogo}
                className="check_logo mt-3 mb-4"
                alt="TrendBay-logo"
              />
              <p className="chch">
                <strong>CHECKOUT</strong>
              </p>
              <div className="checkout__title mt-3">
                <h5>Shipping Address</h5>
                <div className="checkform">
                  <input
                    type="text"
                    value={shippingFullName}
                    placeholder="Full name"
                    className="input"
                    onChange={(e) => setShippingFullName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    value={shippingEmail}
                    placeholder="Email address"
                    className="input"
                    readOnly
                    disabled
                  />
                  <input
                    type="text"
                    value={shippingAddress}
                    placeholder="Address"
                    className="input"
                    onChange={(e) => setShippingAddress(e.target.value)}
                    required
                  />
                  <div className="form-side">
                    <input
                      type="text"
                      value={shippingCity}
                      placeholder="City"
                      className="input"
                      onChange={(e) => setShippingCity(e.target.value)}
                    />
                    <select
                      value={shippingState}
                      onChange={(e) => setShippingState(e.target.value)}
                      className="input select"
                      required
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      <option value="Lagos">Lagos</option>
                      <option value="Abuja">Abuja</option>
                      <optgroup label="Other States">
                        {nigerianStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                    <input
                      type="text"
                      value={shippingCountry}
                      placeholder="Country"
                      className="input"
                      readOnly
                      disabled
                    />
                  </div>

                  <input
                    type="text"
                    value={shippingPhoneNumber}
                    placeholder="Phone number"
                    className="input"
                    onChange={(e) => setShippingPhoneNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="cart_title checkout_title_section">
              <div className="checkout__title">
                <h5>Billing Address</h5>
                <div className="mb-3 mt-2">
                  <input
                    className="mr-2"
                    type="checkbox"
                    checked={shippingSameAsBilling}
                    onChange={handleShippingSameAsBilling}
                  />
                  <span>Same as Shipping?</span>
                </div>
                {!shippingSameAsBilling && (
                  <div className="checkform">
                    <input
                      type="text"
                      value={billingFullName}
                      placeholder="Full name"
                      className="input"
                      onChange={(e) => setBillingFullName(e.target.value)}
                      required={!shippingSameAsBilling}
                    />
                    <input
                      type="email"
                      value={billingEmail}
                      placeholder="Email address"
                      className="input"
                      readOnly
                      disabled
                    />
                    <input
                      type="text"
                      value={billingAddress}
                      placeholder="Address"
                      className="input"
                      onChange={(e) => setBillingAddress(e.target.value)}
                      required={!shippingSameAsBilling}
                    />
                    <div className="form-side">
                      <input
                        type="text"
                        value={billingCity}
                        placeholder="City"
                        className="input"
                        onChange={(e) => setBillingCity(e.target.value)}
                      />
                      <select
                        value={billingState}
                        onChange={(e) => setBillingState(e.target.value)}
                        className="input select"
                        required
                      >
                        <option value="" disabled>
                          Select State
                        </option>
                        <option value="Lagos">Lagos</option>
                        <option value="Abuja">Abuja</option>
                        <optgroup label="Other States">
                          {nigerianStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </optgroup>
                      </select>
                      <input
                        type="text"
                        value={billingCountry}
                        placeholder="Country"
                        className="input"
                        readOnly
                        disabled
                      />
                    </div>

                    <input
                      type="text"
                      value={billingPhoneNumber}
                      placeholder="Phone number"
                      className="input"
                      onChange={(e) => setBillingPhoneNumber(e.target.value)}
                      required={!shippingSameAsBilling}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 payment__summary">
            <CheckoutTotal
              user={user}
              onPaymentSuccess={handlePaymentSuccess}
              email={shippingEmail}
              amount={orderTotal}
              name={shippingFullName}
              phone={shippingPhoneNumber}
              isFormValid={isFormValid}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Checkout;
