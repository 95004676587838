import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Filter.css";
import "../../Components/Boot/Boot.css";

export default function Filter({ onFilterChange }) {
  const [category, setCategory] = useState("");
  const [availability, setAvailability] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const getCategoryFromUrl = () => {
    const url = location.pathname;
    const match = url.match(/\/shop\/([^/]+)/);
    return match ? match[1] : "";
  };

  useEffect(() => {
    const initialCategory = getCategoryFromUrl();
    setCategory(initialCategory || "all");
  }, [location.pathname]);

  const handleFilterChange = () => {
    const priceRanges = {
      "5000-and-below": { minPrice: 0, maxPrice: 5000 },
      "5001-15000": { minPrice: 5001, maxPrice: 15000 },
      "15001-50000": { minPrice: 15001, maxPrice: 50000 },
      "50001-100000": { minPrice: 50001, maxPrice: 100000 },
      "100001-and-above": { minPrice: 100001, maxPrice: Infinity },
    };

    const selectedPriceRange = priceRanges[priceRange] || {};

    onFilterChange({
      category,
      availability,
      ...selectedPriceRange,
    });
  };

  useEffect(() => {
    handleFilterChange();
  }, [category, priceRange, availability]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    navigate(`/shop/${selectedCategory}`);
  };

  const handleResetFilters = () => {
    setCategory("all");
    setPriceRange("");
    setAvailability("");
    onFilterChange({
      category: "all",
      availability: "",
      minPrice: undefined,
      maxPrice: undefined,
    });
  };

  return (
    <div className="filter">
      <div className="filter-group">
        <label className="labeltitle">Category:</label>
        <select value={category} onChange={handleCategoryChange}>
          <option value="all">Select Category</option>
          <option value="fresh-farm-produce">Fresh Farm Produce</option>
          <option value="grains-and-staples">Grains and Staples</option>
          <option value="poultry-and-meat">Poultry and Meat</option>
          <option value="catfish">Catfish</option>
          <option value="dairy-and-eggs">Dairy and Eggs</option>
          <option value="beverages">Beverages</option>
          <option value="snacks-and-confectionery">
            Snacks and Confectionery
          </option>
          <option value="canned-and-packaged-foods">
            Canned and Packaged Foods
          </option>
          <option value="frozen-foods">Frozen Foods</option>
          <option value="condiments-and-sauces">Condiments and Sauces</option>
        </select>
      </div>
      <div className="filter-group">
        <label className="labeltitle">Price Range:</label>
        {[
          "5000-and-below",
          "5001-15000",
          "15001-50000",
          "50001-100000",
          "100001-and-above",
        ].map((range) => (
          <label key={range}>
            <input
              type="radio"
              name="priceRange"
              value={range}
              checked={priceRange === range}
              onChange={(e) => setPriceRange(e.target.value)}
            />
            <span className="checkmark">
              {range === "5000-and-below" && "₦5,000 and Below"}
              {range === "5001-15000" && "₦5,001 - ₦15,000"}
              {range === "15001-50000" && "₦15,001 - ₦50,000"}
              {range === "50001-100000" && "₦50,001 - ₦100,000"}
              {range === "100001-and-above" && "₦100,001 and Above"}
            </span>
          </label>
        ))}
      </div>
      <div className="filter-group reset__filter">
        <a
          href="javascript:void(0)"
          onClick={handleResetFilters}
          className="reset-link"
        >
          Reset Filters
        </a>
      </div>
    </div>
  );
}
